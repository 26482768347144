/* Add document signatory component */
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { parseErrorResponse } from 'core/forms/utils';
import { FieldErrors, ReactSelectField, SubmitButton } from 'core/forms/fields';
import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import ContactSelectField, { contactSelectValidation } from 'contacts/contactSelect/selectField';


const AddSignatoryComponent = ({ signatoriesEnabled, dispatch, signatoryRoles }) => {
  const contentElement = document.querySelector('.app-container');
  const { documentId } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();

  const onSubmit = (values, actions) => {
    const data = {
      legal_entity_id: values.legalEntityId,
      role: values.role,
    };
    axios.post(getApiUrl(`/new-documents/${documentId}/signatories/`, search), data)
      .then(
        ({ data: responseData }) => {
          dispatch({ data: { signatories: responseData, documentId }, type: 'addSignatory' });
          navigate(-1);
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return createPortal(
    <PageContent pageHeading={gettext('Add signatory')}>
      {signatoriesEnabled && (
        <Formik
          initialValues={{
            nonFieldErrors: '',
            legalEntityId: parseInt(new URLSearchParams(window.location.search).get('selected-legal-entity-id'), 10),
            role: 'signatory',
          }}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={Yup.object({
            legalEntityId: contactSelectValidation,
            role: Yup.string().required(gettext('Required')),
          })}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <FieldErrors name="nonFieldErrors" />
              <label htmlFor="legalEntityId">{gettext('Signatory:')}</label>
              <FieldErrors name="legalEntityId" />
              <ContactSelectField
                inputName="legalEntityId"
                onSelect={(value) => setFieldValue('legalEntityId', value)}
                contactPurpose={`document_signatory:${documentId}`}
                includeOwn
                excludeCustodial
                includeCompanyWallets
                selectContactLabel={gettext('Select signatory')}
                addNewLabel={gettext('Add new signatory')}
              />
              <ReactSelectField
                label={gettext('Role')}
                name="role"
                options={signatoryRoles}
              />
              <SubmitButton disabled={isSubmitting}>{gettext('Add')}</SubmitButton>
            </Form>
          )}
        </Formik>
      )}
    </PageContent>,
    contentElement,
  );
};

AddSignatoryComponent.propTypes = {
  signatoriesEnabled: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  signatoryRoles: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
};

export default AddSignatoryComponent;
