/**
 * URL related utilities.
 *
 */
import { getGlobalContext } from 'core/globals';

export const getUrlBase = () => {
  const globalContext = getGlobalContext();
  if (!globalContext) {
    console.error('trustwise variables object is not available');
    return '';
  }
  const {
    activeEntity: { id: activeEntityId, isCompany },
    custodian: { active: custodianActive },
  } = globalContext;
  if (custodianActive || !isCompany) {
    return '';
  }
  return `/company/${activeEntityId}`;
};

export const getUrl = (urlPath = '/', queryArgs = '') => {
  const url = `${getUrlBase()}${urlPath}${!urlPath.endsWith('/') ? '/' : ''}`;
  if (queryArgs) {
    const urlParams = new URLSearchParams(queryArgs);
    return `${url}?${urlParams.toString()}`;
  }
  return url;
};

export const getApiUrlBase = () => `/api/v1${getUrlBase()}`;

export const getApiUrl = (
  urlPath = '/',
  queryArgs: string | Record<string, string> | URLSearchParams = '',
) => {
  const url = `${getApiUrlBase()}${urlPath}${!urlPath.endsWith('/') ? '/' : ''}`;
  if (queryArgs) {
    const urlParams = new URLSearchParams(queryArgs);
    return `${url}?${urlParams.toString()}`;
  }
  return url;
};

export const getUrlWithoutOrigin = (url) => {
  if (!url) {
    return null;
  }
  return `${new URL(url).pathname}${new URL(url).search}`;
};
