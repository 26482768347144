/**
 * Form to transfer cash.
 *
 * @todo refactor after the design system is implemented
 */
import { Formik, Form, ErrorMessage } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { getApiUrl, getUrl } from 'utils/urls';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import { parseErrorResponse } from 'core/forms/utils';
import { TextField, SubmitButton } from 'core/forms/fields';
import axios from 'core/axios';
import ContactSelectField, { contactSelectValidation } from 'contacts/contactSelect/selectField';
import FormErrors from 'components/common/formikErrors';
import BackButton from 'core/page/parts/backButton';
import { PageContent } from 'core/page';
import { formatNumber } from 'core/utils';
import { PaymentTokenModel } from 'investments/paymentTokens/models';

const PaymentTokenTransfer = () => {
  const { portfolioId, tokenId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [token, setToken] = useState(state ? state.token : undefined);


  useEffect(() => {
    if (!token) {
      axios.get(getApiUrl(`/assets/payment-tokens/${portfolioId}/${tokenId}/`))
        .then(({ data }) => setToken(PaymentTokenModel.fromResponse(data)))
        .catch(console.error);
    }
  }, [portfolioId, token, tokenId]);


  const onSubmit = (values, actions) => {
    const data = {
      number_of_tokens: values.numberOfTokens,
      wallet_id: values.walletId,
    };
    axios.post(getApiUrl(`/assets/payment-tokens/${portfolioId}/${tokenId}/transfer/`), data)
      .then(
        ({ data: { tx_hash: txHash } }) => {
          handleTransactionCreation(txHash, getUrl('/assets/cash/'));
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <PageContent
      fullscreen
      pageHeading={token ? gettext(`Transfer of ${token.name}`) : ''}
      headerLeft={<BackButton onClick={() => navigate('/cash/')} />}
    >
      <>
        <div className="row-flex underline bottom-margin">
          <dt role="listitem">{gettext('Balance')}</dt>
          <dd className="font-bold no-overflow">{token ? formatNumber(token.amount) : ''}</dd>
        </div>
        <Formik
          initialValues={{
            nonFieldErrors: '',
            walletId: new URLSearchParams(window.location.search).get('selected-portfolio'),
            numberOfTokens: 0,
          }}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={Yup.object({
            walletId: contactSelectValidation,
            numberOfTokens: Yup.number().min(1, gettext('Value should be above 0')).required(gettext('Required')),
          })}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form className="top-margin">
              <ErrorMessage component={FormErrors} name="nonFieldErrors" />
              <label htmlFor="walletId">{gettext('Recipient:')}</label>
              <ErrorMessage component={FormErrors} name="walletId" />
              <ContactSelectField
                inputName="walletId"
                includeCompanyWallets
                onSelect={(value) => setFieldValue('walletId', value)}
                contactPurpose={`cash_transfer_recipient:${portfolioId}:${tokenId}`}
                purpose="PF"
                excludeCustodial
                selectContactLabel={gettext('Select recipient')}
                addNewLabel={gettext('Add new recipient')}
              />
              <TextField type="number" name="numberOfTokens" label={gettext('Number of tokens:')} />
              <SubmitButton disabled={isSubmitting}>{gettext('Transfer')}</SubmitButton>
            </Form>
          )}
        </Formik>
      </>
    </PageContent>
  );
};

export default PaymentTokenTransfer;
