import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'core/axios';

import { getUrl, getApiUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { BasicRow } from 'core/lists/rows/basicRow';
import { toMonetaryValue } from 'core/utils';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import { formatDateTime } from 'utils/date';
import DocumentsNavigation from 'media/navigation';
import { allValuationTypes } from './options';

const EquityInvestmentValuationDetail = ({ tokenId, valuationEntryId }) => {
  const [valuation, setValuation] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const actions = [];
  if (valuation && !valuation.is_active) {
    actions.push(
      {
        type: 'form',
        icon: 'times',
        label: gettext('Not interested'),
        onClick: () => {
          axios.post(getApiUrl('/inbox/delete-visibility/'), {
            app_label: 'contracts',
            model: 'timeseriesentry',
            object_id: valuationEntryId,
            topic: 'token_valuation',
          })
            .then(window.location = getUrl())
            .catch(console.error);
        },
      },
      {
        type: 'form',
        icon: 'check',
        label: gettext('Set as Active'),
        onClick: () => {
          axios.post(getApiUrl(`/equity/${tokenId}/valuation/${valuationEntryId}/set-active/`))
            .then(window.location = getUrl())
            .then(() => {})
            .catch(console.error);
        },
      },
    );
  }

  useEffect(() => {
    axios.get(getApiUrl(`/equity/${tokenId}/valuation/${valuationEntryId}/`))
      .then(({ data }) => { setValuation(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [tokenId, valuationEntryId]);

  const valuationDisplayName = valuation ? allValuationTypes[valuation.time_series.valuation_type] : '';

  return (
    <PageContent
      pageHeading={gettext('Valuation details')}
      headerLeft={<BackButton href={getUrl()} />}
      actions={actions}
    >
      {valuation ? (
        <>
          <BasicRow title={gettext('Financial instrument')} value={valuation.time_series.token_name} />
          <BasicRow title={gettext('Time series type')} value={valuationDisplayName} />
          <BasicRow title={gettext('Price')} value={toMonetaryValue(valuation.time_series.currency, valuation.value)} />
          <BasicRow title={gettext('Date and time')} value={formatDateTime(new Date(valuation.date_time))} />
          {valuation.comment && <BasicRow title={gettext('Comment')} value={valuation.comment} />}
          <DocumentsNavigation
            relatedObjectModel="timeseriesentry"
            relatedObjectId={valuationEntryId}
          />
        </>
      ) : (
        <ContentLoadingMessage
          isLoading={isLoading}
          loadedMessage={gettext('Valuation not found.')}
        />
      )}
    </PageContent>
  );
};

EquityInvestmentValuationDetail.propTypes = {
  valuationEntryId: PropTypes.string.isRequired,
  tokenId: PropTypes.string.isRequired,
};

export default EquityInvestmentValuationDetail;
