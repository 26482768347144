import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { getUrl } from 'utils/urls';
import { convertBooleanString } from 'utils/convertBooleanString';
import PropertyTokenTransferDetailView from './views/detail';

const PropertyTokenTransfersView = ({ isArbiter }) => {
  const isArbiterView = convertBooleanString(isArbiter);
  const contentElement = document.getElementById('full-page-component');
  const root = createRoot(contentElement);
  root.render(
    <Router basename={getUrl(isArbiterView ? '/arbiter/' : '/transfers/')}>
      <Routes>
        <Route path="/">
          <Route index path=":id/" element={<PropertyTokenTransferDetailView isArbiter={isArbiterView} />} />
        </Route>
      </Routes>
    </Router>,
  );
};

PropertyTokenTransfersView.propTypes = {
  isArbiter: PropTypes.string.isRequired,
};

export default PropertyTokenTransfersView;
