import { BoardMemberDetailModel, BoardMemberListItemModel } from 'boardroom/models/members';
import { BoardMembershipListItemModel } from 'boardroom/models/membership';
import { BoardBusinessTransactionModel } from 'businessTransactions/models';
import axios from 'core/axios';
import { BOARD, ONGOING_GROUP, UPCOMING_GROUP } from 'governanceEvents/const';
import { GovernanceEventListItemModel } from 'governanceEvents/models';
import { getStatusesURLParams } from 'governanceEvents/utils';
import { SimpleDocumentModel } from 'media/models';
import { getApiUrl } from 'utils/urls';

const basUlrPath = '/boardroom/memberships/';
const noOfItems = { no_of_items: '3' };

export const getMemberships = () => (
  axios.get(getApiUrl(basUlrPath))
    .then(({ data }) => BoardMembershipListItemModel.fromResponse(data))
    .catch(console.error)
);

export const getBoardMemberTransfers = (companyId: string) => (
  axios.get(getApiUrl(`${basUlrPath}${companyId}/transfers/`, { ...noOfItems, is_waiting_for_approval: '1' }))
    .then(({ data }) => (
      data.map((item) => BoardBusinessTransactionModel.fromResponse(item).toObject())
    ))
    .catch(console.error)
);

export const getBoardMembersEvents = (companyId: string) => {
  const urlParams = new URLSearchParams({
    ...noOfItems,
    order_by: 'date_time',
    target_group: BOARD,
    owner_id: companyId,
  });
  getStatusesURLParams([ONGOING_GROUP, UPCOMING_GROUP], urlParams);

  return axios.get(`${getApiUrl('/meetings/', urlParams)}`)
    .then(({ data }) => GovernanceEventListItemModel.fromResponse(data))
    .catch(console.error);
};

export const getBoardMembers = (companyId: string) => (
  axios.get(getApiUrl(`${basUlrPath}${companyId}/members/`, noOfItems))
    .then(({ data }) => BoardMemberListItemModel.fromResponse(data))
    .catch(console.error)
);

export const getBoardMembershipDocuments = (companyId: string) => (
  axios.get(getApiUrl(`${basUlrPath}${companyId}/documents/`, noOfItems))
    .then(({ data }) => SimpleDocumentModel.fromArrayResponse(data))
    .catch(console.error)
);

export const getBoardMemberDetails = (companyId: string, memberId: string) => (
  axios.get(getApiUrl(`${basUlrPath}${companyId}/members/${memberId}/`))
    .then(({ data }) => BoardMemberDetailModel.fromResponse(data))
    .catch(console.error)
);
