import { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { getApiUrl } from 'utils/urls';
import axios from 'core/axios';
import Tooltip from 'core/tooltip';
import { ContactsModel, CONFIRMED } from 'contacts/models/contacts';
import { SimpleListRow, ListViewRow } from 'contacts/parts/listViewRow';
import UpdateModal from 'contacts/parts/updateModal';
import { BaseButton } from 'core/forms/fields/button';
import { convertBooleanString } from 'utils/convertBooleanString';
import convertResponseToModel from 'utils/responseToModel';


/**
  * List of contacts to which the user sent a request (i.e. the user is "requested by" entity)
  *
  * @todo DRY with RequestsList
  * @todo add context text to modal confirmation text
*/
const ContactsList = ({ requestedPurpose, byPortfolio, contactUrl, simpleRow, className }) => {
  const [deleteContactModal, setDeleteContactModal] = useState(0);
  const [contacts, setContacts] = useState([]);
  const simpleRowBool = convertBooleanString(simpleRow);

  useEffect(() => {
    axios.get(getApiUrl('/contacts/', { purpose: requestedPurpose }))
      .then(({ data }) => { setContacts(convertResponseToModel(data.results, ContactsModel)); })
      .catch(console.error);
  }, [requestedPurpose]);

  const ListRowComponent = simpleRowBool ? SimpleListRow : ListViewRow;

  const modalContent = (contact) => ({
    confirmationText: contact.status === CONFIRMED ?
      gettext(`Are you sure you would like to remove ${contact.legalEntity.name} from this view`)
      : gettext(`Are you sure you want to cancel the request to ${contact.requestedEmail}?`),
  });

  const contactUpdate = (contact, onSuccess) => {
    const requestedPurposes = [...contact.requestedPurposes];
    requestedPurposes.splice(requestedPurposes.indexOf(requestedPurpose), 1);
    const deleteAllowed = requestedPurposes.length === 0 && contact.status !== CONFIRMED;
    const method = deleteAllowed ? 'delete' : 'patch';
    const data = deleteAllowed ? {} : { requested_purposes: requestedPurposes };
    return axios[method](getApiUrl(`/contacts/${contact.id}/`), data).then(onSuccess(), console.error);
  };

  const tooltipActions = (contact) => {
    const actions = [{
      key: `remove-${contact.id}`,
      content: (
        <BaseButton className="text-tiny dark-grey" onClick={() => { setDeleteContactModal(contact.id); }}>
          <i className={`fa right-padding text-tiny fa-${contact.status === CONFIRMED ? 'user-minus' : 'times'}`} />
          {contact.status === CONFIRMED ? gettext('Remove') : gettext('Cancel request') }
        </BaseButton>
      ),
    }];
    if (contact.status === CONFIRMED) {
      actions.push({
        key: `proceed-${contact.id}`,
        content: (
          <BaseButton
            className="text-tiny dark-grey"
            onClick={() => {
              contactUpdate(contact, () => {
                const urlParams = new URLSearchParams(
                  { 'selected-legal-entity-id': contact.legalEntity.id, 'hide-contacts': true },
                );
                (
                  convertBooleanString(byPortfolio)
                  && urlParams.set('selected-portfolio', contact.allowedPortfolios[0])
                );
                window.location = `${contactUrl}?${urlParams.toString()}`;
              });
            }}
          >
            <i className="fa right-padding text-tiny fa-arrow-circle-right" />
            {gettext('Proceed')}
          </BaseButton>
        ),
      });
    }
    return actions;
  };

  return (
    contacts.length > 0 && (
      <section className="padded-top">
          {!simpleRowBool && (
          <header className="row-flex not-padded">
            <h3 className="title font-size-16px">{gettext('Pending')}</h3>
          </header>
          )}
        <ol>
          {contacts.map((contact) => (
            <Fragment key={contact.id}>
              <UpdateModal
                modalId={`contact-${contact.id}`}
                title={contact.status === CONFIRMED ? gettext('Remove contact from this list') : gettext('Cancel request')}
                isOpen={deleteContactModal === contact.id}
                onClose={() => { setDeleteContactModal(0); }}
                modalContent={modalContent(contact)}
                onSubmit={() => {
                  contactUpdate(contact, () => {
                    const newContacts = [...contacts];
                    newContacts.splice(newContacts.indexOf(contact), 1);
                    setContacts(newContacts);
                    setDeleteContactModal(0);
                  });
                }}
              />
              <li className={`${className} row-flex${simpleRowBool ? ' not-padded' : ' card-base grid-x'}`}>
                <ListRowComponent
                  legalEntity={contact.legalEntity}
                  requestedEmail={contact.requestedEmail}
                  isAnonymous={contact.isAnonymous}
                >
                  <div className="justify-content-flex-end align-flex-items-center">
                    <Tooltip actions={tooltipActions(contact)} />
                  </div>
                </ListRowComponent>
              </li>

            </Fragment>
          ))}
        </ol>
      </section>
    )
  );
};

ContactsList.defaultProps = {
  byPortfolio: false,
  simpleRow: false,
  className: '',
};

ContactsList.propTypes = {
  requestedPurpose: PropTypes.string.isRequired,
  contactUrl: PropTypes.string.isRequired,
  byPortfolio: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  simpleRow: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
};

export default ContactsList;
