import { snakeCase, camelCase } from 'lodash';

import { convertKeysCase } from 'core/utils';
import { datetimeToISO } from 'utils/date';
import { EventTypes, EVENT_STATUS_GROUPS } from './const';

export const getGroupByStatus = (status) => Object.keys(EVENT_STATUS_GROUPS).filter((key) => (
  EVENT_STATUS_GROUPS[key].includes(status)
))[0];

export const prepareSubmitData = (values) => {
  const {
    proxyVotingEnabled,
    proxy,
    chairman,
    secretary,
    date,
    time,
    timeZone,
    location,
    locationCustom,
    locationType,
    videoUrl,
    ...restValues
  } = values;
  const data = convertKeysCase(restValues, snakeCase);
  if (typeof proxy !== 'undefined') {
    data.proxy_id = proxyVotingEnabled ? proxy.id : '';
    data.proxy_voting_enabled = proxyVotingEnabled;
  }
  if (typeof chairman !== 'undefined') {
    data.chairman_id = chairman.id;
  }
  if (typeof secretary !== 'undefined') {
    data.secretary_id = secretary.id;
  }
  if (date && time && timeZone) {
    data.date_time = datetimeToISO(date, time, timeZone);
    data.time_zone = timeZone;
  }
  if (typeof locationType !== 'undefined') {
    data.location_type = locationType;
    data.video_url = videoUrl;
    data.location = location;
    data.location_custom = location === 'custom' ? locationCustom : '';
    switch (locationType) {
      case 'onsite':
        data.video_url = '';
        break;
      case 'remote':
        data.location = '';
        data.location_custom = '';
        break;
      default:
        break;
    }
  }
  return data;
};

export const setResponseErrors = (errors, actions) => {
  Object.keys(errors).forEach((key) => {
    let fieldName = '';
    switch (key) {
      case 'proxy_id':
        fieldName = 'proxy';
        break;
      case 'secretary_id':
        fieldName = 'secretary';
        break;
      case 'chairman_id':
        fieldName = 'chairman';
        break;
      case 'date_time':
        fieldName = 'date';
        break;
      default:
        fieldName = camelCase(key);
        break;
    }
    actions.setFieldError(fieldName, errors[key].join(' '));
  });
  actions.setSubmitting(false);
};

export const isMeetingType = (eventType) => !!eventType && [
  EventTypes.SHAREHOLDERS_MEETING, EventTypes.BOARD_MEETING,
].includes(eventType);

export const isResolutionType = (eventType) => !!eventType && [
  EventTypes.SHAREHOLDERS_RESOLUTION, EventTypes.BOARD_RESOLUTION,
].includes(eventType);

export const getStatusesURLParams = (groups, urlParams) => {
  Object.entries(EVENT_STATUS_GROUPS).forEach(([group, statuses]) => {
    if ((groups).includes(group)) {
      statuses.forEach((status) => { urlParams.append('status', status); });
    }
  });
};
