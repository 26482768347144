import { convertKeysToCamelCase } from 'core/utils';
import { LegalEntityAvatarModel } from 'contacts/models/legalEntity';
import { Model } from 'core/model';

/**
 * Board member list item model
 *
 * Props under restProps are:
 * name, address, email, isChairman, hasCastingVote
 *
 */
export class BoardMemberListItemModel extends Model {
  constructor({
    legalEntityId, avatar, ...restProps
  }) {
    super(restProps);
    this.id = legalEntityId;
    this.avatar = new LegalEntityAvatarModel(convertKeysToCamelCase(avatar));
  }
}

/**
 * Board member detail model
 *
 * Props under restProps are:
 * legalEntityId, avatar, name, email, isChairman, hasCastingVote
 *
 */
export class BoardMemberDetailModel extends BoardMemberListItemModel {
  constructor({
    address, electionDate, ...restProps
  }) {
    super(restProps);
    this.blockchainAddress = address;
    this.electionDate = new Date(electionDate);
  }
}
