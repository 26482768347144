import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { SignatoryDetailView as SignatoryDetailViewDS } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import { SetDisabled } from 'types';
import BackButton from 'core/page/parts/backButton';
import {
  editSignatoryRole,
  editSignatorySignatureType,
  getSignatoryDetails,
  removeSignatory,
} from './actions';
import { EditRoleFormValues, EditSignatoryFormHelpers, EditSignatureTypeFormValues } from '../types';

export const SignatoryDetailView = () => {
  const { signatoryId: pathSignatoryId } = useParams();
  if (!pathSignatoryId) {
    throw new Error('signatoryId is required');
  }

  const { data: signatory } = useQuery({
    queryKey: ['signatory', pathSignatoryId],
    queryFn: () => getSignatoryDetails(pathSignatoryId),
  });

  const signatoryId = Number(pathSignatoryId);

  return (
    <PageContent
      fullscreen
      pageHeading={signatory?.name}
      headerLeft={<BackButton href="/" useRouterLink />}
    >
      <SignatoryDetailViewDS
        {...signatory}
        onSignatoryRoleEdit={(values: EditRoleFormValues, actions: EditSignatoryFormHelpers) => editSignatoryRole({
          signatoryId,
          values: { ...values, signatureType: signatory.signatureType },
          actions,
        })}
        onSignatorySignatureTypeEdit={(values: EditSignatureTypeFormValues, actions: EditSignatoryFormHelpers) => (
          editSignatorySignatureType({
            signatoryId,
            values: { ...values, role: signatory.role },
            actions,
          })
        )}
        onSignatoryRemove={(_ev: React.MouseEvent, setDisabled: SetDisabled) => removeSignatory(signatoryId, setDisabled)}
      />
    </PageContent>
  );
};
