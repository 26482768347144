import { useMemo } from 'react';

import { BOARD } from 'governanceEvents/const';
import { EventsContext } from 'governanceEvents/context';
import { GovEventsRoutes } from 'governanceEvents/navigation';
import { useParams } from 'react-router-dom';


interface BoardEventsProps {
  /**
   * Base path of the membership route
   */
  basePath: string;
}

export const BoardEvents = ({ basePath }: BoardEventsProps) => {
  const { companyId } = useParams();
  if (!companyId) {
    throw new Error('companyId is required');
  }
  const path = `${basePath}/${companyId}/`;

  const contextValue = useMemo(() => ({
    basePath: path,
    indexPath: `/${companyId}/meetings/`,
    targetGroup: BOARD,
    allowedPricingActions: [],
  }), [path, companyId]);

  return (
    <EventsContext.Provider value={contextValue}>
      <GovEventsRoutes ownerId={Number(companyId)} previousPath={path} />
    </EventsContext.Provider>
  );
};
