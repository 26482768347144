/* Document detail component */
import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'core/axios';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import { getApiUrl } from 'utils/urls';
import Header from 'components/common/header';
import { PageContent } from 'core/page';
import DocumentSignatory from 'media/signatories/listItem';
import { getActions } from 'media/utils/actions';
import FileThumbnail from 'media/file/thumbnail';
import DocumentDelete from 'media/documents/delete';
import ContactsList from 'contacts/pending/list';
import BackButton from 'core/page/parts/backButton';


const DocumentDetailComponent = ({
  state,
  dispatch,
  indexPath,
  useIndexPath,
}) => {
  const backUrl = useIndexPath ? indexPath : window.location.pathname.split('documents/')[0];
  const [isLoading, setIsLoading] = useState(true);
  const [showDocumentDeleteModal, setShowDocumentDeleteModal] = useState(false);
  const contentElement = document.querySelector('.app-container');
  const { documentId } = useParams();
  const { search, pathname } = useLocation();
  const baseDocumentUrl = getApiUrl(`/new-documents/${documentId}/`);
  const { documents, isEditable, registrationEnabled, signatoriesEnabled } = state;
  const doc = (documents && documents[documentId]) ? documents[documentId] : {};

  useEffect(() => {
    axios.get(`${baseDocumentUrl}${search}`)
      .then(({ data }) => { dispatch({ data: { doc: data, documentId }, type: 'getDetails' }); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [baseDocumentUrl, search, documentId, dispatch]);

  useEffect(() => {
    if (signatoriesEnabled) {
      axios.get(`${baseDocumentUrl}signatories/${search}`)
        .then(({ data }) => { dispatch({ data: { signatories: data, documentId }, type: 'fetchSignatories' }); })
        .catch(console.error);
    }
  }, [baseDocumentUrl, signatoriesEnabled, search, documentId, dispatch]);

  let actions = [];
  if (doc && doc.status) {
    actions = getActions(
      documentId,
      doc,
      pathname,
      search,
      indexPath,
      isEditable,
      registrationEnabled,
      signatoriesEnabled,
      setShowDocumentDeleteModal,
    );
  }
  return createPortal(
    <PageContent
      pageHeading={(doc && doc.typeText) || gettext('Document')}
      headerLeft={<BackButton href={backUrl} useRouterLink={useIndexPath} />}
      actions={actions}
    >
      {(doc && Object.entries(doc).length > 0) ? (
        <section>
          <Header title={gettext('Files')} className="top-margin underline" />
          <FileThumbnail
            dispatch={dispatch}
            files={doc.files || {}}
            noOfFiles={doc.noOfFiles}
          />

          {(doc.documentDate || doc.subject) && (
            <Header title={gettext('Properties')} className="top-margin underline" />
          )}
          {doc.documentDate && (
            <div className="row-flex padded-top">
              <span>{gettext('Document Date')}</span>
              {doc.formattedDate}
            </div>
          )}
          {doc.subject && (
            <div className="row-flex padded-top">
              <span>{gettext('Subject')}</span>
              {doc.subject}
            </div>
          )}
          <Header title={gettext('Blockchain registration')} className="top-margin underline" />
          {doc.blockchainInfo && doc.blockchainInfo.timestamp ? (
            <section>
              <div className="row-flex padded-top">
                <span>{gettext('Date')}</span>
                <span className="no-overflow">{doc.blockchainInfo.formattedTimestamp}</span>
              </div>
              <div className="row-flex padded-top">
                <span>{gettext('Registered by')}</span>
                <span className="no-overflow align-text-right">
                  {doc.blockchainInfo.recordedBy.name}
                  {doc.blockchainInfo.signatories.length > 0 && (
                    <div className="text-tiny">
                      (
                      {doc.blockchainInfo.signatories.map((signatory) => signatory.name).join(', ')}
                      )
                    </div>
                  )}
                </span>
              </div>
            </section>
          ) : <p className="padded-y text-smaller">{gettext('Not registered yet.')}</p>}

          {signatoriesEnabled && (
            <section>
              <Header title={gettext('Signatories')} className="top-margin" />
              <ContactsList
                simpleRow
                requestedPurpose={`document_signatory:${documentId}`}
                contactUrl={`${pathname}signatories/add/${search}`}
              />
              {doc.signerContract && doc.signerContract.signatories.length > 0 && (
                <ol className="border-top">
                  {doc.signerContract.signatories.map((signatory) => (
                    <li key={signatory.id} className="row-flex underline">
                      <DocumentSignatory
                        signatory={signatory}
                        signerContract={doc.signerContract}
                        dispatch={dispatch}
                      />
                    </li>
                  ))}
                </ol>
              )}
            </section>
          )}

          {doc.tags.length > 0 && (
            <section>
              <Header title={gettext('Tags')} className="top-margin underline" />
              <p className="text-smaller padded-top font-italic">
                <i className="fa fa-fw icon fa-tags" aria-hidden="true" />
                <span className="tiny-left-padding">{doc.tags.map((tag) => tag.name).join(',')}</span>
              </p>
            </section>
          )}
          <DocumentDelete
            isOpen={showDocumentDeleteModal}
            onClose={() => { setShowDocumentDeleteModal(false); }}
            queryParams={search}
            isEditable={isEditable && doc.isEditable}
            docTypeText={doc.typeText}
            onSuccessResponse={() => {
              dispatch({ data: { documentId }, type: 'delete' });
              window.location = indexPath;
            }}
          />
        </section>
      ) : (
        <ContentLoadingMessage
          isLoading={isLoading}
          loadedMessage={gettext('Document not found.')}
        />
      )}
    </PageContent>,
    contentElement,
  );
};

DocumentDetailComponent.defaultProps = {
  useIndexPath: false,
};

DocumentDetailComponent.propTypes = {
  state: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  indexPath: PropTypes.string.isRequired,
  useIndexPath: PropTypes.bool,
};

export default DocumentDetailComponent;
