import { icons } from 'core/icons';
import { MenuItemsIds } from '../constants';
import { MenuItemProps } from '../types';
import { assets, dashboard, documents, events, inbox, markets } from './shared';

export const delegateMenuItems: MenuItemProps[] = [dashboard, inbox, events, assets, markets];

export const custodialMenuItems: MenuItemProps[] = [
  dashboard,
  inbox,
  events,
  assets,
  documents,
  markets,
  {
    id: MenuItemsIds.CONTACTS,
    label: gettext('Contacts'),
    url: '/account/contacts/',
    icon: icons.settingsPersonal,
    activeIcon: icons.settingsPersonalActive,
  },
];
