import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';

import { PageContent } from 'core/page';
import axios from 'core/axios';
import { txHashResponseHandler, convertKeysToCamelCase } from 'core/utils';
import SubscriptionDetailContent from 'subscriptions/generic/detail';
import { getApiUrl } from 'utils/urls';
import BackButton from 'core/page/parts/backButton';
import MintageEventDateForm from './parts/mintageEventDateForm';


const SubscriptionDetail = ({ companyId }) => {
  const [subscription, setSubscription] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [batchesInfo, setBatchesInfo] = useState({});
  const [showMintageReasonDateModal, setShowMintageReasonDateModal] = useState(false);
  const { pathname } = useLocation();
  const { subscriptionId } = useParams();

  useEffect(() => {
    axios.get(getApiUrl(`/subscriptions/${subscriptionId}/`))
      .then(({ data }) => { setSubscription(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [subscriptionId]);

  useEffect(() => {
    if (subscription.status === 'attested') {
      axios.get(getApiUrl(`/subscriptions/${subscriptionId}/batches-info/`))
        .then(({ data }) => { setBatchesInfo(convertKeysToCamelCase(data)); })
        .catch(console.error);
    }
  }, [subscriptionId, subscription.status]);

  const actions = [];
  const cancellationAllowedStatuses = [
    'created', 'subscriptions_allotted', 'selecting_subscriptions',
    'subscriptions_selected', 'attested',
  ];
  if (cancellationAllowedStatuses.includes(subscription.status)) {
    actions.push({
      type: 'form',
      icon: 'times',
      label: gettext('Cancel'),
      onClick: () => {
        axios.delete(getApiUrl(`/subscriptions/${subscriptionId}/cancel/`))
          .then(({ data }) => { txHashResponseHandler(data, pathname); })
          .catch(console.error);
      },
    });
  }
  if (subscription.status === 'attested') {
    const iconName = 'users';
    const label = gettext('Distribute shares');
    const noOfBatches = Math.ceil(batchesInfo.totalSubscribers / batchesInfo.maxBatchSize);
    if (noOfBatches === 1) {
      actions.push({
        type: 'button',
        icon: iconName,
        label,
        onClick: () => { setShowMintageReasonDateModal(true); },
      });
    } else {
      actions.push({
        icon: iconName,
        label,
        url: `${pathname}distribute/`,
        isDisabled: Number.isNaN(noOfBatches),
      });
    }
  }
  if (subscription.status === 'subscriptions_selected') {
    actions.push({
      type: 'form',
      icon: 'check',
      label: gettext('Attest'),
      onClick: () => {
        axios.post(getApiUrl(`/subscriptions/${subscriptionId}/attest/`))
          .then(({ data }) => { txHashResponseHandler(data, pathname); })
          .catch(console.error);
      },
    });
  }


  return (
    <PageContent
      pageHeading={subscription.name || ''}
      headerLeft={<BackButton href={`/company/${companyId}/liabilities/equity/`} />}
      actions={actions}
    >
      <>
        <MintageEventDateForm
          isOpen={showMintageReasonDateModal}
          onClose={() => { setShowMintageReasonDateModal(false); }}
          url={pathname}
          subscriptionId={subscriptionId}
          batchSize={batchesInfo.maxBatchSize}
        />
        <SubscriptionDetailContent
          subscription={subscription}
          isLoading={isLoading}
          ownerView
        />
      </>
    </PageContent>
  );
};

SubscriptionDetail.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default SubscriptionDetail;
