import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { BoardroomListView } from '@trustwise/design-system';
import { BOARD, EventTypes, ONGOING_GROUP, UPCOMING_GROUP } from 'governanceEvents/const';
import { BoardMemberListItemModel } from 'boardroom/models/members';
import { getStatusesURLParams } from 'governanceEvents/utils';
import { getUrl, getApiUrl } from 'utils/urls';
import { GovernanceEventListItemModel } from 'governanceEvents/models';
import { PageContent } from 'core/page';
import { redirectTo } from 'core/utils';
import axios from 'core/axios';
import BackButton from 'core/page/parts/backButton';
import convertResponseToModel from 'utils/responseToModel';
import useContacts from 'contacts/hooks';
import { onNewMemberAdd } from './actions';

const Boardroom = ({ isLocked }) => {
  const [members, setMembers] = useState();
  const [events, setEvents] = useState();
  const navigate = useNavigate();
  const { onFetch } = useContacts(false, { 'entity-type': 'person', 'exclude-custodial': 1 });

  const basePath = '/boardroom/';
  const membersPath = `${basePath}members/`;
  const meetingsBasePath = '/meetings/';

  useEffect(() => {
    if (!isLocked) {
      axios.get(getApiUrl(membersPath))
        .then(({ data }) => {
          setMembers(convertResponseToModel(data, BoardMemberListItemModel));
        })
        .catch(console.error);
    }
  }, [isLocked, membersPath]);

  useEffect(() => {
    if (!isLocked) {
      const urlParams = new URLSearchParams({
        order_by: 'date_time',
        target_group: BOARD,
        no_of_items: 3,
      });
      getStatusesURLParams([ONGOING_GROUP, UPCOMING_GROUP], urlParams);
      axios.get(getApiUrl(meetingsBasePath, urlParams))
        .then(({ data }) => {
          setEvents(convertResponseToModel(data, GovernanceEventListItemModel));
        })
        .catch(console.error);
    }
  }, [isLocked]);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Boardroom')}
      headerLeft={<BackButton href={getUrl()} />}
    >
      <BoardroomListView
        members={members}
        onMemberClick={(id) => navigate(`${id}/`)}
        onNewMemberAdd={(values, actions) => onNewMemberAdd({
          values, actions, submitPath: membersPath, navigatePath: basePath,
        })}
        onContactsFetch={onFetch}
        onContactSearch={({ searchString }) => onFetch(searchString)}
        events={events}
        onSeeAll={() => navigate(meetingsBasePath)}
        onEventClick={(id, type) => {
          navigate(type === EventTypes.BOARD_INFORMATION ? `${meetingsBasePath}informations/${id}/` : `${meetingsBasePath}${id}`);
        }}
        isLocked={isLocked}
        onUpgrade={() => redirectTo('/account/product-offerings')}
      />
    </PageContent>
  );
};

Boardroom.propTypes = {
  isLocked: PropTypes.bool.isRequired,
};

export default Boardroom;
