import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import { copyToClipBoard, messageAutoHide } from 'utils/general';
import { getApiUrl, getUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import Tooltip from 'core/tooltip';
import { BaseButton } from 'core/forms/fields';
import BackButton from 'core/page/parts/backButton';
import axios from 'core/axios';
import { getGlobalContext } from 'core/globals';
import TokenDetails from './parts/tokenDetails';
import InvestmentsDateFilterForm from './InvestmentsDateFilterForm';
import EditPortfolioName from './parts/editPortfolioNameForm';
import AssetsAllocationChart from './parts/chart';
import AssetsValue from './parts/assetsValue';


/**
  * Equity Investments list
  *
  * @todo switch to using reducer for portfolios
  * @todo move chart into a separate file
  * @todo remove nested ternary
  * @todo review and add has_new_valuations
*/
const EquityInvestmentsList = ({ baseCurrency }) => {
  const [tokensPerPortfolio, setTokensPerPortfolio] = useState({});
  const [totalAssetsValue, setTotalAssetsValue] = useState(undefined);
  const [editDate, setEditDate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editNameModal, setEditNameModal] = useState(0);
  const {
    activeEntity: { isCompany },
    custodian: { active: custodianActive },
    delegate: { active: delegateActive },
  } = getGlobalContext();

  const urlSearch = new URL(window.location.href).search;

  useEffect(() => {
    axios.get(getApiUrl('/assets/equity/', urlSearch))
      .then(({ data }) => { setTokensPerPortfolio(groupBy(data, 'portfolio.id')); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [urlSearch]);

  useEffect(() => {
    axios.get(getApiUrl('/assets/equity/total-assets-value/', urlSearch))
      .then(({ data }) => { setTotalAssetsValue(data); })
      .catch(console.error);
  }, [urlSearch]);

  const tooltipActions = (portfolioTokens) => {
    const actions = [
      { key: `edit-${portfolioTokens.portfolio.id}`,
        content: (
          <button
            type="button"
            onClick={() => { setEditNameModal(portfolioTokens.portfolio.id); }}
          >
            <i className="fa right-padding fa-pencil-alt" />
            {gettext('Edit name')}
          </button>
        ) },
      {
        key: `copy-${portfolioTokens.portfolio.id}`,
        content: (
          <BaseButton
            onClick={() => { copyToClipBoard(portfolioTokens.portfolio.address); messageAutoHide(); }}
          >
            <i className="far right-padding fa-copy" />
            {gettext('Copy ID')}
          </BaseButton>
        ),
      },
    ];
    if (!isCompany && !delegateActive && !custodianActive) {
      actions.push({
        key: `manage-signatories-${portfolioTokens.portfolio.id}`,
        content: (
          <a className="tooltip-link" href={getUrl(`/delegation/${portfolioTokens.portfolio.id}/`)}>
            <i className="fa right-padding fa-users" />
            {gettext('Manage signatories')}
          </a>
        ),
      });
    }
    return actions;
  };

  return (
    <PageContent
      pageHeading={gettext('Equity Investments')}
      headerLeft={<BackButton href={getUrl()} />}
      pageMessages={<li className="auto-hide success hidden" key="copied-message">{gettext('Copied')}</li>}
      actions={[
        {
          url: 'ownership/',
          icon: 'building',
          label: gettext('By company'),
          useRouterLink: false,
        },
        {
          type: 'button',
          onClick: () => { setEditDate(!editDate); },
          icon: 'calendar-day',
          label: gettext('As per date'),
        },
      ]}
    >
      <>
        <div>
          <InvestmentsDateFilterForm edit={editDate} />
          <AssetsValue totalAssetsValue={totalAssetsValue} currency={baseCurrency} />
          <AssetsAllocationChart baseCurrency={baseCurrency} urlSearch={urlSearch} />
        </div>
        {Object.entries(tokensPerPortfolio).length > 0 ? (
          Object.values(tokensPerPortfolio).map((portfolioTokens) => (
            <section key={portfolioTokens[0].portfolio.id} className="padded-top">
              {/** @todo Add a name state update */}
              <EditPortfolioName
                isOpen={editNameModal === portfolioTokens[0].portfolio.id}
                portfolioId={portfolioTokens[0].portfolio.id}
                currentName={portfolioTokens[0].portfolio.name}
                onClose={() => { setEditNameModal(0); }}
              />
              <header className="row-flex not-padded">
                <h3 className="title font-size-16px">{portfolioTokens[0].portfolio.name}</h3>
                <div className="bottom-margin justify-content-flex-end align-flex-items-center">
                  <Tooltip actions={tooltipActions(portfolioTokens[0])} />
                </div>
              </header>

              {portfolioTokens.map((token) => (
                <TokenDetails key={token.id} token={token} baseCurrency={baseCurrency} />
              ))}
            </section>
          ))
        ) : (
          <ContentLoadingMessage
            isLoading={isLoading}
            loadedMessage={gettext('No equity investments available')}
          />
        )}
      </>
    </PageContent>
  );
};

EquityInvestmentsList.defaultProps = {
  baseCurrency: 'CHF',
};

EquityInvestmentsList.propTypes = {
  baseCurrency: PropTypes.string,
};

export default EquityInvestmentsList;
