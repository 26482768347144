import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { camelCase } from 'lodash';
import axios from 'core/axios';
import { MonetaryField, ReactSelectField, SubmitButton, TextField, FieldErrors } from 'core/forms/fields';
import DocumentUpload from 'media/documents/upload';
import { DocumentModel } from 'media/models';
import { currencyOptions } from 'core/options';


const PaymentTokenCreate = ({ companyId, initialCurrency }) => {

  const onSubmit = (values, actions) => {
    const data = new FormData();
    data.append('legal_entity_id', companyId);
    data.append('name', values.name);
    data.append('initial_amount', values.initialAmount);
    data.append('currency', values.currency);
    data.append('issuing_price_value', values.issuingPriceValue);
    data.append('issuing_price_currency', values.issuingPriceCurrency);
    data.append('document[date]', values.date);
    data.append('document[subject]', values.subject);
    data.append('document[document_type]', values.documentType);
    values.files.forEach((file) => {
      data.append('document[files]', file);
    });
    axios.post(`/api/v1/company/${companyId}/payment-token-create/`, data)
      .then(
        ({ data: { tx_hash: txHash } }) => {
          const redirectUrl = `/company/${companyId}/payment-tokens/`;
          window.location = `/company/${companyId}/sign/${txHash}/?redirect_url=${redirectUrl}`;
        },
        ({ response: { data: errors } }) => {
          Object.keys(errors).forEach((key) => {
            if (key === 'document') {
              Object.keys(errors[key]).forEach((docKey) => {
                actions.setFieldError(camelCase(docKey), errors[key][docKey].join(' '));
              });
            } else {
              actions.setFieldError(camelCase(key), errors[key].join(' '));
            }
          });
        },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <Formik
      initialValues={{
        nonFieldErrors: '',
        name: '',
        initialAmount: 0,
        currency: initialCurrency,
        issuingPriceValue: (0).toFixed(2),
        issuingPriceCurrency: initialCurrency,
        ...DocumentModel.uploadInitialValues,
      }}
      validationSchema={Yup.object({
        name: Yup.string().required(gettext('This field is required')),
        initialAmount: Yup.number().moreThan(0.01, gettext('Must be above 0')).required(gettext('This field is required')),
        currency: Yup.string().max(3, gettext('Line is too long')).required(gettext('This field is required')),
        issuingPriceValue: Yup.number().positive(gettext('Must be above 0')).required(gettext('This field is required')),
        issuingPriceCurrency: Yup.string().max(3, gettext('Line is too long')).required(gettext('This field is required')),
      }).concat(DocumentModel.uploadValidationRequired)}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <FieldErrors name="nonFieldErrors" />
          <TextField label={gettext('Name')} name="name" />
          <ReactSelectField
            label={gettext('Currency')}
            name="currency"
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.code}
            options={currencyOptions}
          />
          <TextField label={gettext('Number of tokens')} name="initialAmount" type="number" />
          <MonetaryField
            label={gettext('Issuing price')}
            currencyName="issuingPriceCurrency"
            valueName="issuingPriceValue"
          />
          <DocumentUpload />
          <SubmitButton disabled={isSubmitting}>{gettext('Create')}</SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

PaymentTokenCreate.defaultProps = {
  initialCurrency: '',
};

PaymentTokenCreate.propTypes = {
  companyId: PropTypes.string.isRequired,
  initialCurrency: PropTypes.string,
};


export default PaymentTokenCreate;
