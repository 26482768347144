import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import ContentLoadingMessage from 'utils/contentLoadingMessage';
import { PageContent } from 'core/page';
import { getApiUrl } from 'utils/urls';
import Tooltip from 'core/tooltip';
import axios from 'core/axios';
import { allStatuses, CONFIRMED, ContactsModel } from 'contacts/models/contacts';
import { ListViewRow } from 'contacts/parts/listViewRow';
import UpdateModal from 'contacts/parts/updateModal';
import { BaseButton } from 'core/forms/fields/button';
import ScrollableContent from 'core/page/scrollableContent';
import SearchForm from 'core/forms/searchForm';
import BackButton from 'core/page/parts/backButton';
import AddNewContacts from './addNewContacts';

/**
  * List of contacts to which the user sent a request (i.e. the user is "requested by" entity)
  *
  * @todo DRY with RequestsList
*/
const RequestedList = ({
  state,
  isLoading,
  onInitialLoad,
  fetchMore,
  onSearchSubmit,
  dispatch,
  contactsByStatuses,
}) => {
  const { nextPage, contacts } = state;
  // const { hash } = useLocation();
  const navigate = useNavigate();
  // @todo check why if set to true the modal is rendered twice (hash === '#add-contacts-modal-open/')
  const [addContactsModalOpen, setAddContactsModalOpen] = useState(false);
  const [deleteContactModal, setDeleteContactModal] = useState(0);

  const tooltipActions = (contact) => [{
    key: `remove-${contact.id}`,
    content: (
      <BaseButton className="text-tiny dark-grey" onClick={() => { setDeleteContactModal(contact.id); }}>
        <i className={
          `fa right-padding text-tiny fa-${contact.status === CONFIRMED ? 'user-minus' : 'times'}`
        }
        />
        {contact.status === CONFIRMED ? gettext('Remove contact') : gettext('Cancel request')}
      </BaseButton>
    ),
  }];

  useEffect(() => {
    onInitialLoad(getApiUrl('/contacts/'));
  }, [onInitialLoad]);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('My contacts')}
      headerLeft={<BackButton onClick={() => { navigate('/contacts/'); }} />}
      actions={[
        {
          type: 'button',
          icon: 'paper-plane',
          label: gettext('Send new contact request'),
          onClick: () => { setAddContactsModalOpen(true); },
        },
      ]}
    >
      <>
        <AddNewContacts
          isOpen={addContactsModalOpen}
          onClose={() => { setAddContactsModalOpen(false); }}
        />
        <SearchForm onSubmit={({ searchString }) => {
          const queryParam = searchString ? { search: searchString } : {};
          onSearchSubmit(getApiUrl('/contacts/', queryParam));
        }}
        />
        {contactsByStatuses.length > 0 ? (
          <ScrollableContent
            dataLength={contacts.length}
            fetchNext={fetchMore}
            hasMore={!!nextPage}
          >
            <>
              {contactsByStatuses.map((contactByStatus) => (
                <section key={contactByStatus.status} className="padded-top">
                  <header className="row-flex not-padded">
                    <h3 className="title font-size-16px">{allStatuses[contactByStatus.status]}</h3>
                  </header>
                  <ol>
                    {contactByStatus.contacts.map((contact) => (
                      <Fragment key={contact.id}>
                        <UpdateModal
                          modalId={`contact-${contact.id}`}
                          onClose={() => { setDeleteContactModal(0); }}
                          isOpen={deleteContactModal === contact.id}
                          title={contact.status === CONFIRMED ? gettext('Remove contact') : gettext('Cancel request')}
                          modalContent={{
                            confirmationText: gettext(
                              `Are you sure you want to ${contact.status === CONFIRMED
                                ? `remove ${contact.legalEntity.name} from your contacts list`
                                : `cancel the request to ${contact.requestedEmail}`
                              }?`,
                            ),
                          }}
                          onSubmit={() => axios.delete(getApiUrl(`/contacts/${contact.id}/`))
                            .then(
                              (_response) => {
                                dispatch({ data: { activeContact: contact }, type: 'remove' });
                                setDeleteContactModal(0);
                              },
                            )
                            .catch(console.error)}
                        />
                        <li className="row-flex card-base grid-x">
                          <ListViewRow
                            legalEntity={contact.legalEntity}
                            requestedEmail={contact.requestedEmail}
                            isAnonymous={contact.isAnonymous}
                          >
                            <Tooltip actions={tooltipActions(contact)} />
                          </ListViewRow>
                        </li>
                      </Fragment>
                    ))}
                  </ol>
                </section>
              ))}
            </>
          </ScrollableContent>
        ) : <ContentLoadingMessage isLoading={isLoading} loadedMessage={gettext('No contacts')} />}
      </>
    </PageContent>
  );
};

RequestedList.propTypes = {
  state: PropTypes.shape({
    contacts: PropTypes.arrayOf(ContactsModel.propType),
    nextPage: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onInitialLoad: PropTypes.func.isRequired,
  fetchMore: PropTypes.func.isRequired,
  onSearchSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  contactsByStatuses: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default RequestedList;
