import { useState } from 'react';
import { createPortal } from 'react-dom';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import { BaseButton } from 'core/forms/fields';
import { PageContent } from 'core/page';

import BackButton from 'core/page/parts/backButton';
import ContactsList from './list';

const PendingContactsRow = ({ requestedPurpose, contactUrl, byPortfolio }) => {
  const [showContactsList, setShowContactsList] = useState(false);
  const contentElement = document.querySelector('.app-container');

  return (
    <>
      <BaseButton
        className="row-flex full-width dark-grey"
        onClick={() => { setShowContactsList(true); }}
      >
        {gettext('Contact requests')}
        <span className="justify-content-flex-end align-flex-items-center">
          <i className="fa text-tiny fa-chevron-right" aria-hidden="true" />
        </span>
      </BaseButton>
      {showContactsList && createPortal(
        <PageContent
          pageHeading={gettext('Contact requests')}
          headerLeft={<BackButton onClick={() => { setShowContactsList(false); }} />}
        >
          <ContactsList
            requestedPurpose={requestedPurpose}
            contactUrl={contactUrl}
            byPortfolio={byPortfolio}
          />
        </PageContent>,
        contentElement,
      )}
    </>
  );
};

PendingContactsRow.defaultProps = {
  byPortfolio: false,
};

PendingContactsRow.propTypes = {
  requestedPurpose: PropTypes.string.isRequired,
  contactUrl: PropTypes.string.isRequired,
  byPortfolio: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};


const PendingContactsRowAction = ({ requestedPurpose, contactUrl, byPortfolio }) => {
  const [showContactsList, setShowContactsList] = useState(false);
  const contentElement = document.getElementById('full-page-component');
  const root = createRoot(contentElement);

  return (
    <>
      <BaseButton
        className="option success-green text-tiny"
        onClick={() => { setShowContactsList(true); }}
      >
        <i className="fa text-tiny fa-user" />
        {gettext('Pending contacts')}
      </BaseButton>
      {showContactsList && root.render(
        <PageContent
          pageHeading={gettext('Contact requests')}
          headerLeft={<BackButton onClick={() => { setShowContactsList(false); }} />}
        >
          <ContactsList
            requestedPurpose={requestedPurpose}
            contactUrl={contactUrl}
            byPortfolio={byPortfolio}
          />
        </PageContent>,
      )}
    </>
  );
};

PendingContactsRowAction.defaultProps = {
  byPortfolio: false,
};

PendingContactsRowAction.propTypes = {
  requestedPurpose: PropTypes.string.isRequired,
  contactUrl: PropTypes.string.isRequired,
  byPortfolio: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export { PendingContactsRowAction, PendingContactsRow };
