import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { PageContent } from 'core/page';
import { BaseButton } from 'core/forms/fields';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import SearchForm from 'core/forms/searchForm';
import { getApiUrl } from 'utils/urls';
import axios from 'core/axios';
import BackButton from 'core/page/parts/backButton';


const ContactRow = ({ onClick, portfolio, legalEntity, isSelected, isLast }) => (
  <BaseButton
    className={`contact-select-field list-item ${isSelected ? 'selected-item' : ''} ${isLast ? 'last-item' : ''}`}
    onClick={onClick}
  >
    <span className="row-flex not-padded no-overflow">
      <div className="avatar right-padding">
        <span
          className="avatar-image centered"
          style={legalEntity.image ? { background: 'none' } : { backgroundColor: legalEntity.color }}
        >
          {legalEntity.image ? <img alt="profile" className="image" src={legalEntity.image} />
            : <span className="avatar-initials">{legalEntity.initials}</span>}
        </span>
      </div>
      <div>
        <div className="name">{legalEntity.name}</div>
        {portfolio ? <div className="portfolio-address">{portfolio}</div> : null}
      </div>
    </span>
    <i className="fa text-tiny fa-chevron-right" aria-hidden="true" />
  </BaseButton>
);

ContactRow.defaultProps = {
  portfolio: null,
  isSelected: false,
  isLast: false,
};

ContactRow.propTypes = {
  onClick: PropTypes.func.isRequired,
  portfolio: PropTypes.string,
  legalEntity: PropTypes.shape({
    id: PropTypes.number,
    color: PropTypes.string,
    image: PropTypes.string,
    initials: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool,
  isLast: PropTypes.bool,
};


const ContactList = ({
  state,
  purpose,
  dispatch,
  onSelect,
  isLoading,
  selectContactLabel,
  addNewLabel,
  showAddNew,
}) => {
  const { contacts, selectedLegalEntityId, selectedPortfolio, urlQueryArgs } = state;

  const contentElement = document.querySelector('.app-container');
  document.body.classList.add('contacts-overlay-open');

  const onSearchSubmit = ({ searchString }) => {
    const url = getApiUrl('/contacts/', { search: searchString, ...urlQueryArgs });
    axios.get(url)
      .then(({ data }) => { dispatch({ data, type: 'fetch' }); })
      .catch(console.error);
  };

  return createPortal(
    <PageContent
      // @todo avoid this workaround
      styles={{ zIndex: 11 }}
      // @todo avoid this workaround
      isOverlayOpen={false}
      pageHeading={selectContactLabel}
      headerLeft={(
        <BackButton
          onClick={() => {
            document.body.classList.remove('contacts-overlay-open');
            if (selectedLegalEntityId || selectedPortfolio) {
              dispatch({ data: { showContactsList: false }, type: 'showList' });
            } else { window.history.back(); }
          }}
        />
      )}
      actions={!('only-own' in urlQueryArgs) && showAddNew ? [
        {
          type: 'button',
          icon: 'plus',
          label: addNewLabel,
          onClick: () => { dispatch({ data: { showAddView: true }, type: 'showAddView' }); },
        },
      ] : []}
    >
      <>
        <SearchForm onSubmit={onSearchSubmit} />
        {contacts.length > 0 ? (
          <ol className="padded-top" id="contacts-select-list">
            {contacts.map((contact) => (
              purpose === 'PF' ? (
                contact.allowedPortfolios.map((portfolio) => (
                  <ContactRow
                    key={portfolio}
                    onClick={() => {
                      onSelect(portfolio);
                      dispatch({ data: {
                        selectedPortfolio: portfolio,
                        selectedLegalEntityId: contact.legalEntity.id,
                      },
                      type: 'selectPortfolio' });
                    }}
                    portfolio={portfolio}
                    legalEntity={contact.legalEntity}
                    isSelected={portfolio === selectedPortfolio}
                  />
                ))
              ) : (
                <ContactRow
                  key={contact.legalEntity.id}
                  onClick={() => {
                    onSelect(contact.legalEntity.id);
                    dispatch({ data: { selectedLegalEntityId: contact.legalEntity.id }, type: 'select' });
                  }}
                  legalEntity={contact.legalEntity}
                  isSelected={contact.legalEntity.id === selectedLegalEntityId}
                />
              )
            ))}
          </ol>
        ) : (
          <ContentLoadingMessage
            isLoading={isLoading}
            loadedMessage={gettext('No contacts')}
          />
        )}
      </>
    </PageContent>,
    contentElement,
  );
};

ContactList.defaultProps = {
  purpose: '',
  onSelect: () => {},
  isLoading: true,
  selectContactLabel: gettext('Select contact'),
  addNewLabel: gettext('Add new contact'),
  showAddNew: true,
};

ContactList.propTypes = {
  state: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  purpose: PropTypes.string,
  onSelect: PropTypes.func,
  isLoading: PropTypes.bool,
  selectContactLabel: PropTypes.string,
  addNewLabel: PropTypes.string,
  showAddNew: PropTypes.bool,
};


export default ContactList;
