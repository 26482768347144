import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import SubscriptionsList from 'subscriptions/generic/list';
import BackButton from 'core/page/parts/backButton';
import CreateSubscription from './create';
import SubscriptionDetail from './detail';
import SubscriptionCertificatesList from './subscriptionCertificates/list';
import EditFinalSubscriptions from './subscriptionCertificates/editFinalSubscriptions';
import SubscriptionDistribute from './distribute';


const OwnerSubscriptionsNavigation = ({ companyId, initialCurrency }) => {
  const indexPath = window.location.pathname.match(/.*?subscriptions\//)[0];
  return (
    <Router>
      <Routes>
        <Route
          path={`${indexPath}`}
          element={(
            <SubscriptionsList
              fetchDataUrl={`/api/v1/company/${companyId}/subscriptions/`}
              leftUrl={<BackButton href={`/company/${companyId}/liabilities/equity/`} />}
              actionsList={(url) => [{
                url: `${url}create/`,
                icon: 'plus',
                label: gettext('Add'),
              }]}
            />
        )}
        />
        <Route
          path={`${indexPath}create/`}
          element={<CreateSubscription companyId={companyId} initialCurrency={initialCurrency} />}
        />
        <Route
          path={`${indexPath}:subscriptionId/subscription-certificates/`}
          element={<SubscriptionCertificatesList companyId={companyId} />}
        />
        <Route
          path={`${indexPath}:subscriptionId/subscription-certificates/edit-final-subscriptions/`}
          element={<EditFinalSubscriptions companyId={companyId} />}
        />
        <Route
          path={`${indexPath}:subscriptionId/distribute/`}
          element={<SubscriptionDistribute companyId={companyId} />}
        />
        <Route
          path={`${indexPath}:subscriptionId/*`}
          element={<SubscriptionDetail companyId={companyId} />}
        />
      </Routes>
    </Router>
  );
};

OwnerSubscriptionsNavigation.defaultProps = {
};

OwnerSubscriptionsNavigation.propTypes = {
  companyId: PropTypes.string.isRequired,
  initialCurrency: PropTypes.string.isRequired,
};

export default OwnerSubscriptionsNavigation;
