import convertResponseToModel from 'utils/responseToModel';
import { ContactsModel } from 'contacts/models/contacts';

const reducer = (currentState, action) => {
  const { data } = action;
  switch (action.type) {
    case 'fetch': {
      return {
        ...currentState,
        contacts: convertResponseToModel(data, ContactsModel),
      }; }
    case 'select':
      // Renamed from page-content-overlay-open, because it collapses if it's a part of another non fullscreen view
      document.body.classList.remove('contacts-overlay-open');
      return {
        ...currentState,
        selectedLegalEntityId: data.selectedLegalEntityId,
        showContactsList: false,
      };
    case 'selectPortfolio':
      document.body.classList.remove('contacts-overlay-open');
      return {
        ...currentState,
        selectedLegalEntityId: data.selectedLegalEntityId,
        selectedPortfolio: data.selectedPortfolio,
        showContactsList: false,
      };
    case 'showList':
      return {
        ...currentState,
        showContactsList: data.showContactsList,
      };
    case 'showAddView':
      return {
        ...currentState,
        showAddView: data.showAddView,
      };
    default:
      return currentState;
  }
};

export default reducer;
