import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'core/axios';
import { parseErrorResponse } from 'core/forms/utils';
import { SubmitButton, EmailField } from 'core/forms/fields';
import { PageContent } from 'core/page';
import FormErrors from 'components/common/formikErrors';
import { getApiUrl } from 'utils/urls';
import BackButton from 'core/page/parts/backButton';


const AddContact = ({ dispatch, purpose, addNewLabel }) => {
  const contentElement = document.querySelector('.app-container');
  const onSubmit = (values, actions) => {
    actions.setSubmitting(true);
    axios.post(getApiUrl('/contacts/'), { email: values.email, purpose })
      .then(
        () => { window.history.back(); },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return createPortal(
    <PageContent
      pageHeading={addNewLabel}
      headerLeft={<BackButton onClick={() => { dispatch({ data: { showAddView: false }, type: 'showAddView' }); }} />}
    >
      <Formik
        initialValues={{
          nonFieldErrors: '',
          email: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string().email(gettext('Please enter a valid e-mail')).required(gettext('Required')),
        })}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <ErrorMessage component={FormErrors} name="nonFieldErrors" />
            <p className="text-smaller bottom-padding">
              {gettext('Please note: you will need to wait for the recipient to approve your request before proceeding')}
            </p>
            <EmailField label={gettext('E-mail')} name="email" />
            <SubmitButton disabled={isSubmitting}>{gettext('Add')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </PageContent>,
    contentElement,
  );
};

AddContact.defaultProps = {
  addNewLabel: gettext('Add new contact'),
};

AddContact.propTypes = {
  dispatch: PropTypes.func.isRequired,
  purpose: PropTypes.string.isRequired,
  addNewLabel: PropTypes.string,
};


export default AddContact;
