// Create and Edit list of final subscriptions
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';

import axios from 'core/axios';
import { CheckboxField, FieldErrors, SubmitButton } from 'core/forms/fields';
import { parseErrorResponse } from 'core/forms/utils';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';

const EditFinalSubscriptions = ({ companyId }) => {
  const [allSubscriptionsList, setAllSubscriptionsList] = useState([]);
  const [selectedSubscriptionsList, setSelectedSubscriptionsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { subscriptionId } = useParams();

  const subscriptionsUrlPart = `/subscriptions/${subscriptionId}/subscription-certificates/`;
  const baseUrl = `/api/v1/company/${companyId}${subscriptionsUrlPart}`;
  const previousLocationUrl = `/company/${companyId}/liabilities/equity${subscriptionsUrlPart}`;

  useEffect(() => {
    axios.get(`${baseUrl}`)
      .then(({ data }) => { setAllSubscriptionsList(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [baseUrl]);

  useEffect(() => {
    axios.get(`${baseUrl}selected-subscription-certificates/`)
      .then(({ data }) => { setSelectedSubscriptionsList(data); })
      .catch(console.error);
  }, [baseUrl]);

  const onSubmit = (values, actions) => {
    actions.setSubmitting(true);
    if (
      selectedSubscriptionsList.length === values.selectedSubscriptions.length &&
      selectedSubscriptionsList.every((item) => values.selectedSubscriptions.includes(item))
    ) {
      window.location = previousLocationUrl;
    }
    axios.patch(`${baseUrl}update/`, { selected_subscriptions: values.selectedSubscriptions })
      .then(
        ({ data: { tx_hash: txHash } }) => {
          handleTransactionCreation(txHash, previousLocationUrl);
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <PageContent
      pageHeading={gettext('Edit list of final subscriptions')}
      headerLeft={<BackButton href={previousLocationUrl} useRouterLink />}
    >
      <Formik
        initialValues={{
          nonFieldErrors: '',
          selectedSubscriptions: selectedSubscriptionsList,
        }}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={Yup.object({ selectedSubscriptions: Yup.array().of(Yup.string()) })}
      >
        {({ values, isSubmitting }) => (
          <Form>
            {allSubscriptionsList.length ? (
              <>
                <FieldErrors name="nonFieldErrors" />
                <FieldErrors name="selectedSubscriptions" />
                <table className="v-align-middle text-tiny">
                  <thead>
                    <tr>
                      <th className="align-text-left borderless not-padded">{gettext('Name')}</th>
                      <th className="align-text-right borderless not-padded">{gettext('Reference number')}</th>
                      <th className="align-text-right borderless not-padded">{gettext('Payment amount')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray
                      name="selectedSubscriptions"
                      render={(arrayHelpers) => (
                        <>
                          {allSubscriptionsList.map((subscriptionCert) => (
                            <tr key={subscriptionCert.address}>
                              <td>
                                <CheckboxField
                                  id={subscriptionCert.address}
                                  name="selectedSubscriptions"
                                  value={subscriptionCert.address}
                                  label={subscriptionCert.subscriber.name}
                                  style={{ marginBottom: 0 }}
                                  checked={values.selectedSubscriptions.includes(subscriptionCert.address)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      arrayHelpers.push(subscriptionCert.address);
                                    } else {
                                      const index = values.selectedSubscriptions.indexOf(subscriptionCert.address);
                                      arrayHelpers.remove(index);
                                    }
                                  }}
                                />
                              </td>
                              <td className="align-text-right">
                                {subscriptionCert.reference_number}
                              </td>
                              <td className="align-text-right">
                                {`${subscriptionCert.currency}
                                ${(subscriptionCert.no_of_shares * subscriptionCert.price_per_share).toFixed(2)}`}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    />
                  </tbody>
                </table>
                <SubmitButton disabled={isSubmitting}>{gettext('Confirm edit')}</SubmitButton>
              </>
            ) : (
              <ContentLoadingMessage
                isLoading={isLoading}
                loadedMessage={gettext('No subscription certificates available')}
              />
            )}
          </Form>
        )}
      </Formik>
    </PageContent>
  );
};

EditFinalSubscriptions.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default EditFinalSubscriptions;
