import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PageContent } from 'core/page';
import { getUrl } from 'utils/urls';
import BackButton from 'core/page/parts/backButton';
import { getGlobalContext } from 'core/globals';


const ContactsIndex = () => {
  const { pathname } = useLocation();
  const { custodian: { active: custodianActive } } = getGlobalContext();
  const navigate = useNavigate();

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Contacts')}
      headerLeft={(
        <BackButton onClick={() => {
          if (custodianActive) {
            window.location = getUrl();
          } else {
            navigate('/');
          }
        }}
        />
      )}
    >
      <ul className="padded-top">
        <li>
          <Link to={{ pathname: `${pathname}/requests/` }} className="row-flex card-base">
            <span>
              <span className="card-icon icon-padding">
                <i className="fa primary-color fa-user-clock" aria-hidden="true" />
              </span>
              {gettext('Contacts requests')}
            </span>
          </Link>
        </li>
        <li>
          <Link to={{ pathname: `${pathname}/my-contacts/` }} className="row-flex card-base">
            <span>
              <span className="card-icon icon-padding">
                <i className="fa dashboard-blue-green fa-user-friends" aria-hidden="true" />
              </span>
              {gettext('My contacts')}
            </span>
          </Link>
        </li>
      </ul>
    </PageContent>
  );
};

export default ContactsIndex;
