import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { camelCase } from 'lodash';
import { createRoot } from 'react-dom/client';

import axios from 'core/axios';
import { parseErrorResponse } from 'core/forms/utils';
import {
  TextAreaField,
  ReactSelectField,
  SubmitButton,
  FieldErrors,
  DateTimeField,
  MonetaryField,
} from 'core/forms/fields';
import { dateToday, roundedTime } from 'utils/date';
import { DocumentModel } from 'media/models';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import SwitchableDocumentUpload from 'media/documents/switchableUpload';
import { catchHandler } from 'core/utils';
import { manualEstimate, valuationOptions, valuationOptionsKeys } from './options';
import valuationAddValidation from './validation';

const BaseAddValuation = ({ defaultCurrency, redirectUrl, submitUrl, valuationTypes, allowedValuationTypes }) => {
  const contentElement = document.getElementById('full-page-component');
  const root = createRoot(contentElement);

  const onSubmit = (values, actions) => {
    const data = new FormData();
    data.append('time_series[valuation_type]', values.valuationType);
    data.append('time_series[currency]', values.currency);
    data.append('time_series_entry[date_time]', `${values.timeSeriesDate} ${values.timeSeriesTime}`);
    data.append('time_series_entry[comment]', values.comment);
    data.append('time_series_entry[value]', values.value);

    if (values.files && values.files.length) {
      data.append('document[doc_date]', values.date);
      data.append('document[doc_type]', 'FU');
      data.append('document[subject]', values.subject);
      data.append('document[document_type]', values.documentType);
      values.files.forEach((file) => {
        data.append('document[files]', file);
      });
    }

    axios.post(submitUrl, data).then(
      () => { window.location = redirectUrl; },
      ({ response: { data: errors } }) => {
        actions.setSubmitting(false);
        Object.keys(errors).forEach((key) => {
          if (key === 'date_time') {
            actions.setFieldError('timeSeriesDate', errors[key].join(' '));
          } else {
            actions.setFieldError(camelCase(key), errors[key].join(' '));
          }
        });
        parseErrorResponse(errors, actions.setFieldError);
      },
    )
      .catch((error) => catchHandler(error, actions));
  };

  root.render(
    <PageContent
      fullscreen
      pageHeading={gettext('Add valuation')}
      headerLeft={<BackButton href={redirectUrl} />}
    >
      <Formik
        initialValues={{
          nonFieldErrors: '',
          timeSeriesDate: dateToday(),
          timeSeriesTime: roundedTime(),
          valuationType: manualEstimate,
          value: 0,
          currency: defaultCurrency,
          comment: '',
          ...DocumentModel.uploadInitialValues,
        }}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={valuationAddValidation(allowedValuationTypes)}
      >
        {({ isSubmitting }) => (
          <Form>
            <FieldErrors name="nonFieldErrors" />
            <ReactSelectField
              label={gettext('Time series type')}
              name="valuationType"
              options={valuationTypes}
            />
            <DateTimeField label={gettext('Valuation date and time')} dateName="timeSeriesDate" timeName="timeSeriesTime" />
            <MonetaryField label={gettext('Price')} currencyName="currency" valueName="value" />
            <TextAreaField label={gettext('Comment')} name="comment" />
            <SwitchableDocumentUpload />
            <SubmitButton disabled={isSubmitting}>{gettext('Add')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </PageContent>,
  );
};

BaseAddValuation.defaultProps = {
  defaultCurrency: '',
  valuationTypes: valuationOptions,
  allowedValuationTypes: valuationOptionsKeys,
};

BaseAddValuation.propTypes = {
  defaultCurrency: PropTypes.string,
  valuationTypes: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
  })),
  redirectUrl: PropTypes.string.isRequired,
  submitUrl: PropTypes.string.isRequired,
  allowedValuationTypes: PropTypes.arrayOf(PropTypes.string),
};

export default BaseAddValuation;
