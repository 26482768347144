import { useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { BaseButton } from 'core/forms/fields/button';
import { convertBooleanString } from 'utils/convertBooleanString';
import { CONFIRMED } from 'contacts/models/contacts';
import { getApiUrl } from 'utils/urls';
import axios from 'core/axios';
import ContactList from './contactList';
import reducer from './reducer';
import AddContact from './add';

export const contactSelectValidation = Yup.lazy(
  (value) => (typeof value === 'string'
    ? Yup.string().max(42, gettext('Line is too long')).required(gettext('This field is required'))
    : Yup.number().positive().integer().required(gettext('This field is required'))),
);

const ContactSelectField = ({
  inputId,
  inputName,
  inputRequired,
  inputValue,
  purpose,
  inputDisabled,
  showContacts,
  includeOwn,
  onlyOwn,
  onlyPublic,
  serviceType,
  onSelect,
  contactPurpose,
  includeCompanyWallets,
  selectContactLabel,
  addNewLabel,
  showAddNew,
  onlyCompany,
  excludeCustodial,
}) => {
  const urlQueryArgs = { status: CONFIRMED, ordering: 'legal_entity__name' };
  if (serviceType) { urlQueryArgs['service-type'] = serviceType; }
  if (convertBooleanString(onlyOwn)) { urlQueryArgs['only-own'] = 1; }
  if (convertBooleanString(onlyPublic)) { urlQueryArgs['only-public'] = 1; }
  if (convertBooleanString(includeOwn)) { urlQueryArgs['include-own'] = 1; }
  if (convertBooleanString(excludeCustodial)) { urlQueryArgs['exclude-custodial'] = 1; }
  if (convertBooleanString(onlyCompany)) {
    urlQueryArgs['entity-type'] = 'company';
  } else if (!convertBooleanString(includeCompanyWallets)) {
    urlQueryArgs['entity-type'] = 'person';
  }

  const pageForms = document.getElementsByTagName('form');
  let hasFormErrors = false;
  for (let i = 0; i < pageForms.length; i++) {
    if (pageForms[i].getElementsByClassName('errorlist').length > 0) {
      hasFormErrors = true;
      break;
    }
  }
  const isAddress = inputValue.startsWith('0x');

  const urlSearchParams = new URLSearchParams(window.location.search);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [state, dispatch] = useReducer(reducer, {
    contacts: [],
    // try setting defaults for selected legal entity from: inputValue (from uid_field.html if the form has errors)
    // or from url param (in case redirecting from confirmed contact)
    selectedLegalEntityId: (!isAddress && parseInt(inputValue, 10))
    || parseInt(urlSearchParams.get('selected-legal-entity-id'), 10)
    || '',
    selectedPortfolio: (isAddress && inputValue) || urlSearchParams.get('selected-portfolio') || '',
    showContactsList: convertBooleanString(showContacts) && !hasFormErrors && !urlSearchParams.get('hide-contacts'),
    showAddView: false,
    urlQueryArgs,
  });

  useEffect(() => {
    axios.get(getApiUrl('/contacts/', state.urlQueryArgs))
      .then(({ data }) => { dispatch({ data, type: 'fetch' }); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [state.urlQueryArgs]);

  useEffect(() => {
    if (state.selectedPortfolio) {
      setSelectedContact(state.contacts.find((contact) => contact.allowedPortfolios.includes(state.selectedPortfolio)));
    } else if (state.selectedLegalEntityId) {
      setSelectedContact(state.contacts.find((contact) => contact.legalEntity.id === state.selectedLegalEntityId));
    }
  }, [state.contacts, state.selectedLegalEntityId, state.selectedPortfolio, purpose]);

  useEffect(() => {
    document.body.classList.remove('hide-until-overlay-ready');
  }, []);
  return (
    <>
      <BaseButton
        className="contact-select-field bottom-margin-1-2-rem top-margin"
        disabled={convertBooleanString(inputDisabled)}
        onClick={() => { dispatch({ data: { showContactsList: true }, type: 'showList' }); }}
      >
        {selectedContact ? (
          <span className="row-flex not-padded  no-overflow">
            <div className="avatar right-padding">
              <span
                className="avatar-image centered"
                style={
                  selectedContact.legalEntity.image
                    ? { background: 'none' }
                    : { backgroundColor: selectedContact.legalEntity.color }
                  }
              >
                {selectedContact.legalEntity.image
                  ? <img alt="profile" className="image" src={selectedContact.legalEntity.image} />
                  : <span className="avatar-initials">{selectedContact.legalEntity.initials}</span>}
              </span>
            </div>
            <span>
              <div className="name">{selectedContact.legalEntity.name}</div>
              {purpose === 'PF' ? <div className="portfolio-address">{state.selectedPortfolio}</div> : null}
            </span>
          </span>
        ) : <span className="text-tiny font-italic">{isLoading ? gettext('Loading...') : gettext('Select contact')}</span>}
        <i className="fa text-tiny fa-chevron-right" aria-hidden="true" />
      </BaseButton>
      <input
        id={inputId || inputName}
        name={inputName}
        required={convertBooleanString(inputRequired)}
        value={state.selectedPortfolio || state.selectedLegalEntityId}
        readOnly
        className="hidden"
      />
      {state.showContactsList && !state.showAddView && (
        <ContactList
          purpose={purpose}
          state={state}
          isLoading={isLoading}
          dispatch={dispatch}
          onSelect={onSelect}
          selectContactLabel={selectContactLabel}
          addNewLabel={addNewLabel}
          showAddNew={convertBooleanString(showAddNew)}
        />
      )}
      {state.showAddView && <AddContact purpose={contactPurpose} dispatch={dispatch} addNewLabel={addNewLabel} />}
    </>
  );
};

ContactSelectField.defaultProps = {
  inputId: '',
  inputValue: '',
  purpose: '',
  showContacts: true,
  inputRequired: true,
  inputDisabled: false,
  includeCompanyWallets: false,
  includeOwn: false,
  onlyOwn: false,
  onlyPublic: false,
  onlyCompany: false,
  excludeCustodial: false,
  serviceType: '',
  contactPurpose: '',
  onSelect: () => { },
  selectContactLabel: gettext('Select contact'),
  addNewLabel: gettext('Add new contact'),
  showAddNew: true,
};

ContactSelectField.propTypes = {
  inputId: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showContacts: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  inputRequired: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  inputDisabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  purpose: PropTypes.string,
  contactPurpose: PropTypes.string,
  includeCompanyWallets: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  includeOwn: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onlyOwn: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onlyPublic: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showAddNew: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onlyCompany: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  excludeCustodial: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  serviceType: PropTypes.string,
  onSelect: PropTypes.func,
  selectContactLabel: PropTypes.string,
  addNewLabel: PropTypes.string,
};


export default ContactSelectField;
