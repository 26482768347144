import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { getUrl } from 'utils/urls';
import { RegistriesListView, RegistryDetailView, RegistryMemberDetailView } from './views';

const RegistriesView = () => {
  const contentElement = document.getElementById('full-page-component');
  const root = createRoot(contentElement);

  root.render(
    <Router basename={getUrl('/registries/')}>
      <Routes>
        <Route path="/">
          <Route index element={<RegistriesListView />} />
          <Route path=":registryId/">
            <Route index element={<RegistryDetailView />} />
            <Route path="members/:memberId/" element={<RegistryMemberDetailView />} />
          </Route>
        </Route>
      </Routes>
    </Router>,
  );
};

export default RegistriesView;
