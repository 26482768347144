import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { EventsListView } from '@trustwise/design-system';
import { actionsIcons } from 'core/icons';
import { ASC_SORTING_ORDER, DESC_SORTING_ORDER } from 'core/const';
import { EVENT_STATUS_GROUPS, GROUP_INFORMATION_TYPES, BOARD, SHAREHOLDERS } from 'governanceEvents/const';
import { getGlobalContext } from 'core/globals';
import { getUrl, getApiUrl } from 'utils/urls';
import { AllowedCompanyActions, isCompanyActionAllowed } from 'core/allowedActions';
import { PageContent } from 'core/page';
import axios from 'core/axios';
import BackButton from 'core/page/parts/backButton';
import convertResponseToModel from 'utils/responseToModel';
import { useAbortController } from 'core/hooks';
import { GovernanceEventListItemModel } from '../models';
import { EventsContext } from '../context';

const GovernanceEventsList = ({ ownerId, previousPath }) => {
  const [events, setEvents] = useState(undefined);
  const { state } = useLocation();
  const [currentTab, setCurrentTab] = useState(state && state.lastActiveTab ? state.lastActiveTab : 'upcoming');
  const navigate = useNavigate();
  const { allowedPricingActions, targetGroup } = useContext(EventsContext);
  const { activeEntity: { isCompany } } = getGlobalContext();
  const canManageEvents = isCompany && isCompanyActionAllowed(AllowedCompanyActions.EVENTS) && !!allowedPricingActions.length;
  const allowedActions = [];
  const allowedTabs = ['upcoming', 'ongoing', 'past'];

  const [, updateController] = useAbortController();

  useEffect(() => {
    const signal = updateController();

    setEvents(undefined);
    let queryArgs = targetGroup ? `target_group=${targetGroup}` : '';
    if (ownerId) {
      queryArgs = `${queryArgs}&owner_id=${ownerId}`;
    }
    if (currentTab === 'past') {
      queryArgs = `${queryArgs}&order=desc`;
    }
    // Get statuses for provided events tab
    EVENT_STATUS_GROUPS[currentTab].forEach(
      (group) => {
        queryArgs = `${queryArgs}&status=${group}`;
      },
    );

    axios.get(getApiUrl('/meetings/', queryArgs), { signal })
      .then(({ data }) => { setEvents(convertResponseToModel(data, GovernanceEventListItemModel)); })
      .catch(console.error);
  }, [currentTab, targetGroup, ownerId, updateController]);

  // List is passed for future sorting by column behaviour when we implement it
  const onSort = (_list, isAsc) => axios.get(
    getApiUrl('/meetings/', { page_size: 1000, order: isAsc ? ASC_SORTING_ORDER : DESC_SORTING_ORDER, order_by: 'date_time' }),
  )
    .then(({ data }) => { setEvents(convertResponseToModel(data, GovernanceEventListItemModel)); })
    .catch(console.error);


  if (canManageEvents) {
    allowedTabs.push('in_preparation');
    allowedActions.push({
      id: 'createNewEvent',
      label: gettext('Create new event'),
      onClick: () => { navigate('create/'); },
      autoReset: false,
      iconLeft: actionsIcons.plus,
    });
  }

  let pageHeading;
  switch (targetGroup) {
    case BOARD:
      pageHeading = gettext('Board Events');
      break;
    case SHAREHOLDERS:
      pageHeading = gettext("Shareholders' Events");
      break;
    default:
      pageHeading = gettext('Events');
      break;
  }

  return (
    <PageContent
      fullscreen
      pageHeading={pageHeading}
      headerLeft={<BackButton href={getUrl(previousPath)} />}
      actions={allowedActions}
      actionsMode="priorityActions"
    >
      <EventsListView
        events={events}
        onItemClick={(itemId, itemType) => {
          navigate(
            GROUP_INFORMATION_TYPES.includes(itemType) ? `informations/${itemId}/` : `${itemId}/`,
            { state: { lastActiveTab: currentTab } },
          );
        }}
        onTabSelect={(tabId) => { setCurrentTab(tabId); }}
        allowedLabels={allowedTabs}
        initialTabId={currentTab}
        onEventsSort={onSort}
      />
    </PageContent>
  );
};

GovernanceEventsList.defaultProps = {
  ownerId: null,
  previousPath: '/',
};

GovernanceEventsList.propTypes = {
  ownerId: PropTypes.number,
  previousPath: PropTypes.string,
};

export default GovernanceEventsList;
