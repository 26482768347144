import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { getUrl } from 'utils/urls';
import PageNotFoundContent from 'core/page/contentParts/404';
import {
  MultisigTransactionDetailView,
  MultisigTransactionsListView,
  SignatoriesListView,
  SignatoryDetailView,
} from './views';

const queryClient = new QueryClient();

const SignatoriesNavigation = () => {
  const contentElement = document.getElementById('full-page-component');
  if (!contentElement) {
    return;
  }

  const root = createRoot(contentElement);

  root.render(
    <QueryClientProvider client={queryClient}>
      <Router basename={getUrl('/signatories')}>
        <Routes>
          <Route path="/">
            <Route index element={<SignatoriesListView />} />
            <Route path=":signatoryId/" element={<SignatoryDetailView />} />
            <Route path="multisig-transactions/" element={<MultisigTransactionsListView />} />
            <Route path="multisig-transactions/:multisigTransactionId/" element={<MultisigTransactionDetailView />} />
          </Route>
          <Route path="*" element={<PageNotFoundContent />} />
        </Routes>
      </Router>
    </QueryClientProvider>,
  );
};

export default SignatoriesNavigation;
