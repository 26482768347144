import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import { getUrl } from 'utils/urls';
import PageNotFoundContent from 'core/page/contentParts/404';
import MarketsSubscriptionsNavigation from 'subscriptions/markets/navigation';
import {
  MarketTilesListView,
  ArtworksMarketDetailView,
  PropertyTokenOrderDetailView,
  MarketListingsView,
} from './views';
import { MarketsListingContextProvider } from './context';

const MarketsView = ({ availableMarketplaces }) => {
  const contentElement = document.getElementById('full-page-component');
  const root = createRoot(contentElement);
  const marketplaces = availableMarketplaces ? availableMarketplaces.split(' ') : [];

  root.render(
    <Router basename={getUrl('/markets/')}>
      <Routes>
        <Route path="/">
          <Route index element={<MarketTilesListView availableMarketplaces={marketplaces} />} />
          <Route path=":marketType/">
            <Route index element={<MarketListingsView />} />
            <Route path=":tokenId/" element={<MarketsListingContextProvider />}>
              <Route index element={<ArtworksMarketDetailView />} />
              <Route path="orders/:orderId/" element={<PropertyTokenOrderDetailView />} />
            </Route>
          </Route>
          <Route path="private-equity/subscriptions/*" element={<MarketsSubscriptionsNavigation />} />
        </Route>
        <Route path="*" element={<PageNotFoundContent />} />
      </Routes>
    </Router>,
  );
};

MarketsView.defaultProps = {
  availableMarketplaces: '',
};

MarketsView.propTypes = {
  availableMarketplaces: PropTypes.string,
};

export default MarketsView;
