import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { PageContent } from 'core/page';
import FormErrors from 'components/common/formikErrors';
import { SubmitButton } from 'core/forms/fields';
import { parseErrorResponse } from 'core/forms/utils';
import axios from 'core/axios';
import ContactSelectField, { contactSelectValidation } from 'contacts/contactSelect/selectField';
import BackButton from 'core/page/parts/backButton';


const AddMember = ({ companyId }) => {
  const { listId } = useParams();
  const { search } = useLocation();

  const urlBase = `/company/${companyId}/ownership/potential-shareholders/${listId}/members/`;
  const onSubmit = (values, actions) => (
    axios.post(`/api/v1${urlBase}`, { legal_entity_id: values.legalEntityId })
      .then(
        () => { window.location = urlBase; },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); })
  );

  return (
    <PageContent
      pageHeading={gettext('Add member')}
      headerLeft={<BackButton href={urlBase} useRouterLink />}
    >
      <Formik
        initialValues={{
          nonFieldErrors: '',
          legalEntityId: parseInt(new URLSearchParams(search).get('selected-legal-entity-id'), 10),
        }}
        validationSchema={Yup.object({
          legalEntityId: contactSelectValidation,
        })}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <ErrorMessage component={FormErrors} name="nonFieldErrors" />
            <label htmlFor="legalEntityId">{gettext('Member')}</label>
            <ErrorMessage component={FormErrors} name="legalEntityId" />
            <ContactSelectField
              inputName="legalEntityId"
              includeOwn
              includeCompanyWallets
              onSelect={(value) => setFieldValue('legalEntityId', value)}
              contactPurpose={`potential_shareholder:${listId}`}
              selectContactLabel={gettext('Select whitelist member')}
              addNewLabel={gettext('Add new whitelist member')}
            />
            <SubmitButton disabled={isSubmitting}>{gettext('Save')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </PageContent>
  );
};


AddMember.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default AddMember;
