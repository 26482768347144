import { useReducer, useState, useCallback } from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import { groupBy } from 'lodash';

import axios from 'core/axios';
import ContactsIndex from './contactsIndex';
import RequestDetails from './requests/details';
import RequestsList from './requests/list';
import RequestedList from './requested/list';
import reducer from './reducer';

const ContactsNavigation = () => {
  const [state, dispatch] = useReducer(reducer, {
    contacts: [],
    nextPage: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const contactsByStatus = state.contacts ? groupBy(state.contacts, 'status') : {};
  const contactsByStatuses = Object.keys(contactsByStatus).reduce((array, key) => [...array, {
    status: key,
    contacts: contactsByStatus[key].sort((a, b) => (b.createdAt > a.createdAt ? 1 : -1)),
  }], []).sort((a, b) => (b.status > a.status ? 1 : -1));

  const onInitialLoad = useCallback((url) => {
    dispatch({ data: null, type: 'cleanState' });
    setIsLoading(true);
    axios.get(url)
      .then(({ data }) => { dispatch({ data, type: 'fetch' }); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, []);

  const fetchMore = () => {
    axios.get(state.nextPage)
      .then(({ data }) => { dispatch({ data, type: 'fetchNext' }); })
      .catch(console.error);
  };

  const onSearchSubmit = (url) => {
    dispatch({ data: null, type: 'cleanState' });
    setIsLoading(true);
    axios.get(url)
      .then(({ data }) => { dispatch({ data, type: 'fetch' }); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  };

  return (
    <Routes>
      <Route path="/">
        <Route index element={<ContactsIndex />} />
        <Route
          path="requests/"
          element={(
            <RequestsList
              state={state}
              isLoading={isLoading}
              onInitialLoad={onInitialLoad}
              fetchMore={fetchMore}
              onSearchSubmit={onSearchSubmit}
              contactsByStatuses={contactsByStatuses}
            />
            )}
        />
        <Route
          path="requests/:contactId/"
          element={(
            <RequestDetails state={state} dispatch={dispatch} />
            )}
        />
        <Route
          path="my-contacts/"
          element={(
            <RequestedList
              isLoading={isLoading}
              onInitialLoad={onInitialLoad}
              fetchMore={fetchMore}
              onSearchSubmit={onSearchSubmit}
              dispatch={dispatch}
              state={state}
              contactsByStatuses={contactsByStatuses}
            />
          )}
        />
      </Route>
    </Routes>
  );
};

export default ContactsNavigation;
