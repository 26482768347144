import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import PotentialShareholdersWhitelists from './list';
import WhitelistMembers from './members/list';
import FilterBasedList from './filterBasedList';
import AddMember from './members/add';
import AddWhitelist from './add';


const ShareholdersWhitelistsNavigation = ({ companyId }) => {
  const indexPath = window.location.pathname.match(/.*?potential-shareholders\//)[0];
  return (
    <Router>
      <Routes>
        <Route
          path={`${indexPath}`}
          element={<PotentialShareholdersWhitelists companyId={companyId} />}
        />
        <Route
          path={`${indexPath}add/`}
          element={<AddWhitelist companyId={companyId} />}
        />
        <Route
          path={`${indexPath}:listId/members/`}
          element={<WhitelistMembers companyId={companyId} />}
        />
        <Route
          path={`${indexPath}filters-set-list/`}
          element={<FilterBasedList companyId={companyId} />}
        />
        <Route
          path={`${indexPath}:listId/members/add/`}
          element={<AddMember companyId={companyId} />}
        />
      </Routes>
    </Router>
  );
};

ShareholdersWhitelistsNavigation.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default ShareholdersWhitelistsNavigation;
