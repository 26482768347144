import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import { getUrl } from 'utils/urls';
import { isNativeAppWebview, isNewNativeAppWebview } from 'utils/general';
import { getGlobalContext } from 'core/globals';
import ProfileDetail from './views/detail';
import ContactsNavigation from '../contacts/navigation';
import EraseAllDataView from './views/eraseDeviceData';
import PricingTransactionsListView from '../pricing/views/transactionsList';
import ProductOfferingsNavigation from '../pricing/productOffering/navigation';

const ProfileView = () => {
  const isWebView = isNativeAppWebview || isNewNativeAppWebview;
  const { activeEntity: { isCompany } } = getGlobalContext();
  const contentElement = document.getElementById('full-page-component');
  const root = createRoot(contentElement);
  root.render(
    <Router basename={getUrl('/account/')}>
      <Routes>
        <Route path="/">
          <Route index element={<ProfileDetail />} />
          <Route path="contacts/*" element={<ContactsNavigation />} />
          <Route path="product-offerings/*" element={<ProductOfferingsNavigation />} />
          <Route path="pricing/transactions/" element={<PricingTransactionsListView />} />
          {isWebView && !isCompany && <Route path="erase-data/" element={<EraseAllDataView />} />}
        </Route>
      </Routes>
    </Router>,
  );
};

export default ProfileView;
