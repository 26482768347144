import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'core/axios';
import { BasicRow } from 'core/lists/rows/basicRow';
import { CONFIRMED, ContactsModel, PENDING, REJECTED, REVOKED } from 'contacts/models/contacts';
import { getApiUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import BackButton from 'core/page/parts/backButton';
import { getGlobalContext } from 'core/globals';
import UpdateModal from '../parts/updateModal';


const RequestDetails = ({ state, dispatch }) => {
  const { contacts } = state;
  const { contactId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [entityPortfolios, setEntityPortfolios] = useState([]);
  const [isContactUpdateModalOpen, setIsContactUpdateModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const activeContact = contacts.length ? contacts.find((contact) => `${contact.id}` === contactId) : null;
  const { custodian: { active: custodianActive } } = getGlobalContext();

  useEffect(() => {
    if (!contacts.length) {
      axios.get(getApiUrl(`/contacts/requests/${contactId}`))
        .then(({ data }) => { dispatch({ data, type: 'fetchSingle' }); })
        .catch(console.error);
    }
  }, [contacts, contactId, dispatch]);

  useEffect(() => {
    if (activeContact) {
      const { legalEntity: { isCompany, id } } = activeContact;
      let url = isCompany ? '/contracts/company/' : '/contracts/personal/';
      if (custodianActive && isCompany) {
        url = `/company/${id}/contracts/company/`;
      }
      axios.get(getApiUrl(url, !isCompany ? { purpose: 'PF' } : ''))
        .then(({ data }) => { setEntityPortfolios(data.map(({ address }) => address)); })
        .catch(console.error);
    }
  }, [activeContact, custodianActive]);

  const onSubmit = () => {
    axios.patch(getApiUrl(`/contacts/requests/${contactId}/`), modalContent.data)
      .then(
        ({ data }) => {
          dispatch({ data: { activeContact, update: data }, type: modalContent.actionType });
          if (location.state) {
            navigate(-1);
          } else {
            window.location = document.referrer;
          }
        },
      )
      .catch(console.error);
  };

  const actions = [
    {
      type: 'button',
      icon: 'user-times',
      label: activeContact && activeContact.status === PENDING ? gettext('Reject') : gettext('Remove'),
      onClick: () => {
        setIsContactUpdateModalOpen(true);
        const rejection = activeContact.status === PENDING;
        setModalContent({
          data: { status: rejection ? REJECTED : REVOKED },
          actionType: 'remove',
          header: rejection ? gettext('Reject request') : gettext('Remove contact'),
          confirmationText: gettext(
            `Are you sure you want to ${
              rejection ? 'accept the request from' : 'remove your account from contacts list of'
            } ${activeContact.requestedBy.name}?`,
          ),
        });
      },
    },
  ];
  if (activeContact && activeContact.status === PENDING) {
    actions.push({
      type: 'button',
      icon: 'user-check',
      label: gettext('Accept'),
      onClick: () => {
        setIsContactUpdateModalOpen(true);
        setModalContent({
          data: { status: CONFIRMED, allowed_portfolios: entityPortfolios },
          actionType: 'update',
          header: gettext('Accept request'),
          confirmationText: gettext(`Are you sure you want to accept the request from ${activeContact.requestedBy.name}?`),
        });
      },
    });
  }

  return (
    <PageContent
      fullscreen
      pageHeading={activeContact ? activeContact.requestedBy.name : ''}
      headerLeft={<BackButton onClick={() => { navigate('/contacts/requests/'); }} />}
      actions={actions}
    >
      {activeContact ? (
        <>
          <UpdateModal
            modalId="contactUpdateModal"
            isOpen={isContactUpdateModalOpen}
            onClose={() => { setIsContactUpdateModalOpen(false); }}
            title={modalContent.header}
            modalContent={modalContent}
            onSubmit={onSubmit}
          />
          <BasicRow title={gettext('Email')} value={activeContact.requestedBy.email} />
          {activeContact.requestedBy.type === 'company' ? (
            <>
              <BasicRow title={gettext('Legal ID')} value={activeContact.requestedBy.legalId} />
              <BasicRow title={gettext('Address')} value={activeContact.requestedBy.address} />
            </>
          ) : null}
        </>
      ) : <ContentLoadingMessage isLoading={!contacts.length} loadedMessage={gettext('Contact not found.')} /> }
    </PageContent>
  );
};

RequestDetails.propTypes = {
  state: PropTypes.shape({
    contacts: PropTypes.arrayOf(ContactsModel.propType),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default RequestDetails;
