export const ProvidedServices = {
  ARBITER: 'AR',
  VERIFIER: 'VE',
  EXCHANGE: 'EX',
  CUSTODIAN: 'CU',
  REGISTRAR: 'RE',
  CERTIFICATE_ISSUER: 'CI',
  OBJECT_MANAGER: 'OM',
  UTILITY_TOKEN_ISSUER: 'UTI',
  REGISTRY_MANAGER: 'RM',
} as const;

export type ProvidedService = typeof ProvidedServices[keyof typeof ProvidedServices];
