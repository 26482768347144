import PropTypes from 'prop-types';

import { convertKeysToCamelCase } from 'core/utils';
import { LegalEntityAvatarResponseProps } from 'contacts/interfaces/response';
import { Model } from 'core/model';
import { LegalEntityAvatarProps, LegalEntityType, SimpleLegalEntityModelProps } from 'contacts/interfaces/types';

const allTypes = [
  { id: 'person', value: gettext('Person') },
  { id: 'company', value: gettext('Company') },
];

export class LegalEntityModel {
  id: number;
  name: string;
  email: string;
  type: LegalEntityType;
  image: string;
  initials: string;
  color: string;
  legalId: string;
  homepage: string;
  address: string;

  constructor({
    id,
    name,
    email,
    type,
    image,
    initials,
    color,
    legalId,
    homepage,
    companyAddress,
  }) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.type = type;
    this.image = image;
    this.initials = initials;
    this.color = color;
    this.legalId = legalId;
    this.homepage = homepage;
    this.address = companyAddress;
  }

  static propType = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
    initials: PropTypes.string,
    color: PropTypes.string,
  });

  get typeText() {
    const typeText = allTypes.find((type) => type.id === this.type);
    return typeText ? typeText.value : '';
  }

  get isCompany() {
    return this.type === 'company';
  }

}

export class LegalEntityAvatarModel extends Model {
  backgroundColor?: string;

  constructor({
    color, ...restProps
  }: LegalEntityAvatarResponseProps) {
    super(restProps);
    this.backgroundColor = color || undefined;
  }
}

/**
 * Simple LegalEntity Model
 *
 * Props under restProps are:
 * entityType, email
 *
 */
export class SimpleLegalEntityModel extends Model {
  id: number;
  name: string;
  avatar?: LegalEntityAvatarModel;
  timeZone?: string;
  portfolio?: string;

  constructor({
    id,
    avatar = {
      initials: '',
      color: '',
      image: '',
    },
    timezone,
    portfolioAddress,
    name,
    ...restProps
  }: SimpleLegalEntityModelProps) {
    super(restProps);
    this.id = id;
    this.avatar = new LegalEntityAvatarModel(convertKeysToCamelCase(avatar) as LegalEntityAvatarProps);
    this.timeZone = timezone;
    this.portfolio = portfolioAddress;
    this.name = name;
  }
}
