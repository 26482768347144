import { snakeCase } from 'lodash';

import { PageContent } from 'core/page';
import axios from 'core/axios';
import { parseErrorResponse } from 'core/forms/utils';
import BackButton from 'core/page/parts/backButton';
import { convertKeysCase } from 'core/utils';
import { getGlobalContext } from 'core/globals';
import BaseAccountEditForm from './baseAccountEditForm';


const CustodialAccountEdit = () => {
  const {
    activeEntity: { id: activeEntityId, name: activeEntityName },
    custodian: { id: custodianId },
  } = getGlobalContext();
  const urlBase = `/api/v1/company/${custodianId}`;

  const onSubmit = (values, actions) => {
    const data = convertKeysCase(values, snakeCase);
    const url = `${urlBase}/custodian/shareholders/${activeEntityId}/`;
    axios.patch(url, data)
      .then(
        () => { window.location = '/'; },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .then(() => { actions.setSubmitting(false); });
  };

  return (
    <PageContent
      pageHeading={gettext(`Edit ${activeEntityName} Account`)}
      headerLeft={<BackButton href="/" />}
    >
      <BaseAccountEditForm
        isEditing
        accountId={activeEntityId}
        urlBase={urlBase}
        onSubmit={onSubmit}
        submitLabel={gettext('Update')}
      />
    </PageContent>
  );
};

export default CustodialAccountEdit;
