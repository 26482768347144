import { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { DashboardView, SuggestedDashboardBanners } from '@trustwise/design-system';
import axios from 'core/axios';
import { FullPageContent } from 'core/page';
import { getApiUrl } from 'utils/urls';
import { getGlobalContext } from 'core/globals';
import { redirectTo } from 'core/utils';
import {
  custodialDefaultWidgets,
  delegateDefaultWidgets,
  widgets as allWidgets,
  companyDefaultWidgets,
  personDefaultWidgets,
} from './const';
import {
  bannersActions,
  companyDefaultActions,
  managedAccountDefaultActions,
  personDefaultActions,
} from './suggestions';
import { useScreenSize } from '../core/hooks';


// This separation is needed to avoid conflict of ReactDOM.render() in Dashboard having state update
const Dashboard = ({ baseCurrency }) => {
  const {
    activeEntity: { status, isCompany, id: entityId },
    custodian: { active: custodianActive },
    delegate: { active: delegateActive },
  } = getGlobalContext();

  const isVerified = status === 'verified';
  const isManagedAccount = custodianActive || delegateActive;
  const { isXSScreen } = useScreenSize();

  const [entityWidgets, setEntityWidgets] = useState();
  const [suggestedBanners, setSuggestedBanners] = useState();
  const [suggestedActions, setEntitySuggestedActions] = useState();

  let defaultActions = personDefaultActions;
  if (isManagedAccount) {
    defaultActions = managedAccountDefaultActions;
  } else if (isCompany) {
    defaultActions = companyDefaultActions;
  }

  useEffect(() => {
    if (custodianActive) {
      setEntityWidgets(custodialDefaultWidgets);
    } else if (delegateActive) {
      setEntityWidgets(delegateDefaultWidgets);
    } else {
      axios.get(getApiUrl('/dashboard-widgets/'))
        .then(({ data: { widgets: widgetsList } }) => {
          if (widgetsList && widgetsList.length > 0) {
            setEntityWidgets(widgetsList);
          } else {
            setEntityWidgets(isCompany ? companyDefaultWidgets : personDefaultWidgets);
          }
        })
        .catch(console.error);
    }
  }, [custodianActive, delegateActive, isCompany]);

  useEffect(() => {
    axios.get(getApiUrl('/settings/suggestions/'))
      .then(
        ({ data }) => {
          setSuggestedBanners(data);
          setEntitySuggestedActions(defaultActions.filter((action) => !data.includes(action)));
        },
        console.error,
      );
  }, [defaultActions]);

  const onBannerClose = (bannerId) => {
    axios.post(getApiUrl('/settings/suggestions/'), suggestedBanners.filter((item) => item !== bannerId))
      .then(
        ({ data }) => {
          setSuggestedBanners(data);
          setEntitySuggestedActions(defaultActions.filter((action) => !data.includes(action)));
        },
        console.error,
      );
  };
  const onActionClick = (id) => bannersActions[id]();

  const banners = {
    includedBanners: suggestedBanners,
    onClick: onActionClick,
    onClose: onBannerClose,
  };

  return (
    <FullPageContent
      noPadding
      pageHeading={gettext('Dashboard')}
      headerLeft={<div />}
    >
      <DashboardView
        legalEntityId={entityId}
        onDashboardEdit={!isManagedAccount ? () => redirectTo('/edit-dashboard/') : undefined}
        suggestions={isVerified ? {
          banners,
          actions: {
            includedActions: suggestedActions,
            onClick: onActionClick,
          },
        } : undefined}
      >
        {/* This is a workaround until inbox and documents widgets are integrated */}
        {entityWidgets ? (
          entityWidgets.map((entityWidget, index) => {
            const WidgetComponent = allWidgets[entityWidget];
            const displayDashboardBanners = isVerified && isXSScreen && index === 0 && !!suggestedBanners;

            return (
              <Fragment key={entityWidget}>
                <WidgetComponent baseCurrency={baseCurrency} />
                {displayDashboardBanners && <SuggestedDashboardBanners {...banners} />}
              </Fragment>
            );
          })
        ) : undefined}
      </DashboardView>
    </FullPageContent>
  );
};

Dashboard.propTypes = {
  baseCurrency: PropTypes.string.isRequired,
};

export default Dashboard;
