import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import SubscriptionCertificatesList from './list';
import SubscriptionCertificatesDetail from './detail';


const EquitySubscriptionsNavigation = () => {
  const indexPath = window.location.pathname.match(/.*?subscriptions\//)[0];
  return (
    <Router>
      <Routes>
        <Route
          path={`${indexPath}`}
          element={<SubscriptionCertificatesList />}
        />
        <Route
          path={`${indexPath}:subscriptionCertificateId/*`}
          element={<SubscriptionCertificatesDetail />}
        />
      </Routes>
    </Router>
  );
};


export default EquitySubscriptionsNavigation;
