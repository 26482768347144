import { redirectTo } from 'core/utils';

const bannersActions = {
  add_contact: () => redirectTo('/account/contacts/my-contacts/#add-contacts-modal-open'),
  register_company: () => redirectTo('/registration/company/'),
  add_signatory: () => redirectTo('/signatories/'),
};

const companyDefaultActions = ['add_contact', 'add_signatory'];
const personDefaultActions = ['add_contact', 'register_company'];
const managedAccountDefaultActions = ['add_contact'];

export {
  bannersActions,
  companyDefaultActions,
  personDefaultActions,
  managedAccountDefaultActions,
};
