import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import PageNotFoundContent from 'core/page/contentParts/404';
import { BOARD } from 'governanceEvents/const';
import { convertBooleanString } from 'utils/convertBooleanString';
import { EventsContext } from 'governanceEvents/context';
import { getGlobalContext } from 'core/globals';
import { getUrl } from 'utils/urls';
import { GovEventsRoutes } from 'governanceEvents/navigation';
import {
  Boardroom,
  BoardMemberDetail,
} from './views';

const BoardroomView = ({ allowedEventsPricingActions, isLocked }) => {
  const contentElement = document.getElementById('full-page-component');
  const root = createRoot(contentElement);

  const { activeEntity: { id: activeEntityId } } = getGlobalContext();
  const basePath = '/boardroom';

  root.render(
    <Router basename={getUrl(basePath)}>
      <Routes>
        <Route path="/">
          <Route index element={<Boardroom isLocked={convertBooleanString(isLocked)} />} />
          <Route path=":memberId/" element={<BoardMemberDetail />} />
        </Route>
        <Route
          path="meetings/*"
          element={(
            <EventsContext.Provider value={{
              basePath,
              indexPath: '/meetings/',
              targetGroup: BOARD,
              allowedPricingActions: allowedEventsPricingActions ? allowedEventsPricingActions.split(' ') : [],
            }}
            >
              <GovEventsRoutes ownerId={activeEntityId} previousPath={basePath} />
            </EventsContext.Provider>
          )}
        />
        <Route path="*" element={<PageNotFoundContent />} />
      </Routes>
    </Router>,
  );
};

BoardroomView.defaultProps = {
  allowedPricingActions: '',
};

BoardroomView.propTypes = {
  allowedPricingActions: PropTypes.string,
  isLocked: PropTypes.string.isRequired,
};

export default BoardroomView;
