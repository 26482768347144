import { isPlainObject, camelCase } from 'lodash';

import { getUrl } from 'utils/urls';
import { parseErrorResponse } from 'core/forms/utils';
import { getGlobalContext } from 'core/globals';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';

/**
 * Convert object keys case.
 *
 */
export const convertKeysCase = (obj, conversionMethod) => Object.entries(obj).reduce(
  (accumulator, [key, value]) => ({ ...accumulator, [conversionMethod(key)]: value }),
  {},
);

export const convertKeysToCamelCase = (obj) => convertKeysCase(obj, camelCase);

export const convertNestedKeysCase = (obj, conversionMethod) => {
  if (!conversionMethod || !obj) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((v) => convertNestedKeysCase(v, conversionMethod));
  }
  const result = convertKeysCase(obj, conversionMethod);
  Object.entries(result).forEach((entry) => {
    const [key, value] = entry;
    if (isPlainObject(value) || Array.isArray(value)) {
      result[key] = convertNestedKeysCase(value, conversionMethod);
    }
  });
  return result;
};

export const convertNestedKeysToCamelCase = (obj) => convertNestedKeysCase(obj, camelCase);

export const formatNumber = (value, digits = 2) => (
  value.toLocaleString('de-ch', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  })
);

export const toMonetaryValue = (currency, value) => `${currency} ${value}`;

export const txHashResponseHandler = (response, responseRedirect) => {
  if (response && 'tx_hash' in response) {
    handleTransactionCreation(response.tx_hash, responseRedirect);
  } else {
    window.location = responseRedirect;
  }
};

export const getCurrentlyDisplayedRange = (
  totalPages,
  currentPage,
  previousRange,
  responseLength,
  nextPage,
  totalDataLength,
) => {
  const { rangeStart, rangeEnd } = previousRange;
  if (nextPage < currentPage && nextPage !== 1) {
    return {
      rangeEnd: rangeStart - 1,
      rangeStart: rangeStart - responseLength,
    };
  }
  if (nextPage === 1) {
    return {
      rangeEnd: responseLength,
      rangeStart: 1,
    };
  }
  if (nextPage === totalPages) {
    return {
      rangeEnd: totalDataLength,
      rangeStart: totalDataLength - responseLength + 1,
    };
  }
  return { rangeStart: rangeEnd + 1, rangeEnd: rangeEnd + responseLength };
};

export const getStringBetweenSubstrings = ({ str, start, end }) => {
  const startSubStrMatch = str.match(start);
  const startSubStr = startSubStrMatch ? startSubStrMatch[0] : '';
  const endSubStrMatch = str.match(end);
  const endSubStr = endSubStrMatch ? endSubStrMatch[0] : '';
  const indexOfStrStart = str.indexOf(startSubStr) + startSubStr.length;
  const indexOfStrEnd = str.indexOf(endSubStr);
  return str.substring(indexOfStrStart, indexOfStrEnd);
};

export const catchHandler = (error, actions) => {
  if (error.response) {
    try {
      parseErrorResponse(error.response.data, actions.setFieldError);
    } catch (e) {
      console.error(e);
    }
  } else {
    console.error(error);
  }
  if (actions) {
    actions.setSubmitting(false);
  }
  return Promise.reject();
};

export const redirectTo = (path) => { window.location.href = getUrl(path); };

export const openNativeView = (path) => {
  const { nativeUrlScheme } = getGlobalContext();
  window.location = `${nativeUrlScheme}${path}`;
};
